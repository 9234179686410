import API from "./AxiosHeaders";
import {endPoints} from "./APIEndPoints";

const getAllOrders = async () => {
    return await API.commonInstance.get(endPoints.getAllOrders);
}
const getSingleOrderData = async (orderId) => {
    return await API.commonInstance.get(endPoints.getSingleOrderDetails+`/`+orderId);
}
const saveNewOrderStatus = async (orderId,status) => {
    return await API.commonInstance.post(endPoints.changeOrderStatus,{ id: orderId,status:status } );
}

const saveNewPaymentStatus = async (orderId,status) => {
    return await API.commonInstance.post(endPoints.changePaymentStatus, { orderId: orderId,status:status });
}


const OrderAPI = {
    getAllOrders,
    getSingleOrderData,
    saveNewOrderStatus,
    saveNewPaymentStatus
};

export default OrderAPI;