// Auth
const signIn = "/web/user/login";
const forgotPassword = "/web/user/forgotPassword";
const resetPassword = "/web/user/resetPassword";
const logout = "/";
const changePassword = "/web/user/changePassword";

// location
const saveLocation = "/web/locations/add";
const getAllLocation = "/web/locations/get";
const editLocation = "/web/locations/edit";
const deleteLocation = "/web/locations/delete";

// branch
const saveBranch = "/web/branches/add";
const getAllBranch = "/web/branches/get";
const getActiveBranches = "/branches/get";
const editBranch = "/web/branches/edit";
const deleteBranch = "/web/branches/delete";

// category
const saveCategory = "/web/categories/add";
const getAllCategory = "/web/categories/get";
const getMainCategory = "/web/mainCategories/get";
const editCategory = "/web/categories/edit";
const deleteCategory = "/web/categories/delete";
const activeCategoryList = "/web/categoriesList/get";
const changeCategoryStatus = "/web/categories/changeStatus";
const getCategoryWithMasterIds = "/web/categories/masterIds";
const saveBulkCategory = "/web/categories/copyFromMaster";

// users
const saveUser = "/web/user/add";
const getAllUsers = "/web/users/get";
const editUser = "/web/user/edit";
const deleteUser = "/web/user/delete";

// products
const getAllProducts = "/web/products/get";
const getActiveProducts = "/";
const editProductData = "/web/product/edit";
const deleteProduct = "/web/product/delete";
const getProductDetails = "/web/product";
const changeProductStatus = "/web/product/changeStatus";
const quickEditProductData = "/web/product/quickEdit";
const getProductWithMasterIds = "/web/products/masterIds";
const saveBulkProducts = "/web/products/copyFromMaster";

// stock management
const getAvailableStock = "/";
const saveNewAdjustment = "/web/stock/adjust";
const getAllAdjustment = "/web/get/adjustments";
const getSingleAdjustment = "/";
const getBranchStockList = "/web/get/branchStock";

// customers
const getAllCustomers = "/web/customers/get";
const getSingleCustomerDetails = "/web/customer";


// orders
const getAllOrders = "/web/orders/get";
const getSingleOrderDetails = "/web/order";
const changeOrderStatus = "/web/order/changeStatus";
const changePaymentStatus = "/";


// Organisation
const getActiveOrganisation = "/web/organisation/activeList";

// master files
const getMasterCategories = "/web/master/getCategoriesList";
const getMasterProductForOrg= "/web/master/getOrgProductList";
const saveNewProduct = "/web/master/addProduct";
const getMasterProducts = "/web/master/getProductList";
const changeMasterProductStatus = "/web/master/changeProductStatus";
const getAllMasterCategories = "/web/master/allCategories";
const changeMasterCategoryStatus = "/web/master/changeCategoryStatus";

// menu 
const getAccessMenus = "/web/routes/menuList";

// account 
const getLoggedAccountDetails = "/web/account/getData";
const accountDeleteRequest = "/web/delete_user";


export const  endPoints = {
    signIn,
    forgotPassword,
    resetPassword,
    logout,
    saveLocation,
    getAllLocation,
    editLocation,
    deleteLocation,
    saveBranch,
    getAllBranch,
    getActiveBranches,
    editBranch,
    deleteBranch,
    saveCategory,
    getAllCategory,
    getMainCategory,
    editCategory,
    deleteCategory,
    saveUser,
    editUser,
    getAllUsers,
    deleteUser,
    saveNewProduct,
    getAllProducts,
    getActiveProducts,
    editProductData,
    deleteProduct,
    getProductDetails,
    getAvailableStock,
    saveNewAdjustment,
    getAllAdjustment,
    getSingleAdjustment,
    getAllCustomers,
    getSingleCustomerDetails,
    getAllOrders,
    getSingleOrderDetails,
    changeOrderStatus,
    changePaymentStatus,
    activeCategoryList,
    getBranchStockList,
    changePassword,
    changeProductStatus,
    quickEditProductData,
    changeCategoryStatus,
    getMasterCategories,
    getCategoryWithMasterIds,
    saveBulkCategory,
    getMasterProductForOrg,
    getProductWithMasterIds,
    saveBulkProducts,
    getAccessMenus,
    getMasterProducts,
    changeMasterProductStatus,
    getAllMasterCategories,
    changeMasterCategoryStatus,
    getActiveOrganisation,
    getLoggedAccountDetails,
    accountDeleteRequest
}
