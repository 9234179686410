import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import {Button, DatePicker, Radio, Space} from "antd";
import moment from "moment";
import TextInput from "../../components/form/TextInput";
import Label from "../../components/form/Label";
import {ORG_ID} from "../../AppConstant";
import CategoryAPI from "../../services/CategoryAPIServices";
import ProductAPI from "../../services/ProductAPIServices";
import $ from 'jquery'
import MasterFileAPI from "../../services/MasterFileAPIServices";

function NewProduct() {
    const { RangePicker } = DatePicker;
    const [title, setTitle] = useState("");
    const [discount, setDiscount] = useState('0');
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("1");
    const [master_category_id, setCategoryId] = useState("");
    const [discount_start_date, setDiscountStart] = useState("");
    const [discount_end_date, setDiscountEnd] = useState("");
    const [image, setImage] = useState("");
    const [org_id, setOrgId] = useState(ORG_ID);
    const [categoryList, setCategoryList] = useState([]);
    const [price, setPrice] = useState("0");


    useEffect(() => {
        MasterFileAPI.getMasterCategories().then((response)=>{

            setCategoryList(response.data.data);
        });
    }, []);

    const onChangeStatus = (e) => {
        setStatus(e.target.value);
    };


    const onChangeDate = (dates, dateStrings) => {
        if (dates) {
            setDiscountStart(dateStrings[1]);
            setDiscountEnd(dateStrings[0]);
        } else {
            setDiscountStart("");
            setDiscountEnd("");
        }
    };

    const encodeImageFileAsURL = async (element) => {
        let file = element.target.files[0];
        let reader = new FileReader();
        reader.onloadend = function() {
            setImage(reader.result)
        }
        reader.readAsDataURL(file);
    }

    const handleNewProductCreate = (e) => {
        e.preventDefault();

        const data = {title,description,master_category_id,status,org_id,discount,discount_start_date,discount_end_date,price};

        if(parseFloat(discount).toFixed(2) < 0){
            Swal.fire("Warning", "Minus value is not allowed for discount field", "warning");
        }else if(parseFloat(discount).toFixed(2) > 0 && (discount_start_date === "" || discount_end_date === "")){
            Swal.fire("Warning", "Please add the discount valid date range", "warning");
        }else if(parseFloat(price).toFixed(2) <= 0 || price === ""){
            Swal.fire("Warning", "Minus or zero value is not allowed for price field", "warning");
        }else{
            $("#submitButton").prop("disabled", true);
            ProductAPI.saveNewProduct(data).then((response) => {

                if (response.data.status_code === 0) {
                    Swal.fire("Success", ""+response.data.message, "success").then(function() {
                        window.location.replace("/master-products");
                    });
                }else{
                    $("#submitButton").prop("disabled", false);
                    Swal.fire("Warning", " " + response.data.message, "warning");
                }
            }).catch((error) => {
                $("#submitButton").prop("disabled", false);
                Swal.fire(""+error.response.data.message, ""+error.response.data.errors[0].location.path.replace('/', "")+" - " + error.response.data.errors[0].message, "warning");
            });
        }
    };

    return (
        <>
            <div className="container-xxl">
            <div className="row">
                <div className="col-8">
                    <h3> Add Product </h3>
                </div>
                <div className="col-4">
                    <button
                        type="button"
                        className="btn btn-primary mb-3 d-block  float-right"
                        id={"submitButton"}
                        onClick={(e) => handleNewProductCreate(e)}
                    >
                        Create Product
                    </button>
                </div>
            </div>
            <div className="row g-3 mb-3">
                <div className="col-xl-8 col-lg-8">
                    <div className="sticky-lg-top">
                        <div className="card mb-3">
                            <div className="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                                <h6 className="m-0 fw-bold">Basic Information</h6>
                            </div>
                            <div className="card-body">
                                <div className="row g-3 align-items-center">
                                    <div className="col-md-12">
                                    <Label label={"Name"} required={true}/>
                                        <TextInput
                                           
                                            placeholder="Product Name"
                                            name="product_name"
                                            onchange={(e) => setTitle(e.target.value)}
                                            value={title}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <Label label={"Product Description"} required={true}/>
                                        <textarea className="form-control" id="description" name="description" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Enter Product Description Here" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                                <h6 className="m-0 fw-bold">Pricing Info</h6>
                            </div>
                            <div className="card-body">
                                <div className="row g-3 align-items-center">
                                    <div className="col-md-6">
                                    <Label label={"Price"} required={true}/>
                                        <TextInput
                                            
                                            placeholder="Price"
                                            name="price"
                                            type={'number'}
                                            min={1}
                                            onchange={(e) => setPrice(e.target.value)}
                                            value={price}
                                        />
                                    </div>
                                    <div className="col-md-6"></div>
                                    {/* <div className="col-md-6">
                                        <TextInput
                                            label={"Discount"}
                                            placeholder="Discount"
                                            name="discount"
                                            type={'number'}
                                            min={1}
                                            onchange={(e) => setDiscount(e.target.value)}
                                            value={discount}
                                        />
                                    </div> */}
                                    {/* <div className="col-md-6">
                                        <label className="form-label">Discount Date Range</label>
                                            <RangePicker
                                                ranges={{
                                                    Today: [moment(), moment()],
                                                    "This Month": [
                                                        moment().startOf("month"),
                                                        moment().endOf("month"),
                                                    ],
                                                }}
                                                onChange={onChangeDate}
                                            />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                    <div className="card mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                            <h6 className="m-0 fw-bold">Visibility Status</h6>
                        </div>
                        <div className="card-body">
                            <div className="row g-3 align-items-center">
                            <div className="col-md-12">
                            <Radio.Group onChange={onChangeStatus} value={status}>
                                <Space direction="vertical">
                                    <Radio value={"1"}> <Label label={"Published"} /> </Radio>
                                    <Radio value={"0"}> <Label label={"Hidden"}/> </Radio>
                                </Space>
                            </Radio.Group>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                            <h6 className="m-0 fw-bold">Other Details</h6>
                        </div>
                        <div className="card-body">
                            <div className="row g-3 align-items-center">
                            <div className="col-md-12 mb-5">
                            <Label label={"Category"} required={true}/>
                            <select className="form-control" id="master_category_id" name="master_category_id" value={master_category_id}  onChange={(e) => setCategoryId(e.target.value)}>
                                <option value="" disabled selected> Select category </option>
                                {categoryList.map((item, index) => (<>
                                     <option value={item.key} style={{fontWeight:500}} key={item.key}> {item.name}</option>

                                    {item.children.map((subItem, i) => (
                                        <option value={subItem.key} key={subItem.key}>&emsp; {subItem.name}</option>
                                    ))}

                                </>))}
                            </select>
                            </div>
                            {/* <div className="col-md-12 mb-2" >
                                <label className="form-label">Image </label>
                                <input type="file" name="image" id="image" accept="image/*" className="form-control" onChange={encodeImageFileAsURL} required/>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}
export default NewProduct;
