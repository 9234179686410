import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import {Link} from "react-router-dom";
import PerfectScrollbar from 'perfect-scrollbar';
import RoutesAPI from "../services/RoutesAPIServices";

function Sidebar() {

  const [accessMenu, setAccessMenus] = useState([]);
  useEffect(() => {
    const ps = new PerfectScrollbar('.sidebar-content');
    ps.update();
    RoutesAPI.getAccessMenuRoutes().then((response) => {
      setAccessMenus(response.data.data);

    }).catch((error) => { console.error(error) });
  }, []);

  return (
    <>
      <div
        className="app-sidebar menu-fixed"
        data-background-color="man-of-steel"
        data-scroll-to-active="true"
      >
        <div className="sidebar-header">
          <div className="logo clearfix"><a className="logo-text float-left" >
            <div className="logo-img"><img style={{maxWidth:40}} src=".../../../app-assets/img/Foxatab_logo_1.svg" alt="Logo" /></div><span className="text">Foxatab</span>
          </a>
            <a className="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;"><i className="ft-x"></i></a>
          </div>
        </div>
        <div className="sidebar-content main-menu-content">
          <div className="nav-container">
            <ul
              className="navigation navigation-main"
              id="main-menu-navigation"
              data-menu="menu-navigation"
            >
              {accessMenu.map((menu, i) => {
                return (
                  <li className=" nav-item">
                  <Link to={`${menu.href}`}>
                    <i className={`${menu.icon}`}></i><span className="menu-title" >{menu.name}</span>
                  </Link>
                </li>
                );
              })}
          

            </ul>
          </div>
        </div>
        <div className="sidebar-background"></div>
      </div>
    </>
  );
}

export default Sidebar;
