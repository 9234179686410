import API from "./AxiosHeaders";
import {endPoints} from "./APIEndPoints";

const signInApi = async (username, password) => {
    return await API.authInstance.post(endPoints.signIn, { email: username, password: password, org_id:1 });
}

const forgotPasswordApi = async (email) => {
    return await API.authInstance.put(endPoints.forgotPassword+`/`+email);
}

const resetPasswordApi = async (data) => {
    return await API.authInstance.post(endPoints.resetPassword, data);
}

const signOutApi = async () => {
    return await API.commonInstance.post(endPoints.logout);
}

const changePasswordApi = async (data) => {
    return await API.commonInstance.post(endPoints.changePassword, data);
}

const getLoggedAccountDetails = async () => {
    return await API.commonInstance.get(endPoints.getLoggedAccountDetails);
}

const AuthAPI = {
    signInApi,
    forgotPasswordApi,
    resetPasswordApi,
    signOutApi,
    changePasswordApi,
    getLoggedAccountDetails
};

export default AuthAPI;