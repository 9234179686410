import axios from "axios";
import CommonFunctions from "../utils/CommonFunctions";
import {API_BASE_URI} from "../AppConstant";

const authInstance = axios.create({
    baseURL: API_BASE_URI,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*"
    },
});

const commonInstance = axios.create({
    baseURL: API_BASE_URI,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization" : CommonFunctions.getAuthorizationHeader()
    },
});

const mediaHeadersInstance = axios.create({
    baseURL: API_BASE_URI,
    headers: {
        "Content-Type": "multipart/form-data;",
        "Authorization" : CommonFunctions.getAuthorizationHeader("access_token")
    },
});


const API = {
    authInstance,
    commonInstance,
    mediaHeadersInstance
}

export default API;