import API from "./AxiosHeaders";
import {endPoints} from "./APIEndPoints";


const getActiveOrganisation = async () => {
    return await API.commonInstance.get(endPoints.getActiveOrganisation);
}

const OrganisationAPI = {
    getActiveOrganisation
};

export default OrganisationAPI;