import React, { useState, useEffect, useRef } from "react";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import { Table, Modal, Input, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import TextInput from "../../components/form/TextInput";
import CategoryAPI from "../../services/CategoryAPIServices";
import { IMAGE_BASE_URL, ORG_ID } from "../../AppConstant";
import DropDown from "../../components/form/DropDown";
import Label from "../../components/form/Label";
import $ from 'jquery';


function Category() {
  const inputRef = useRef();
  const [tableData, setTableData] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [display_order, setDisplayOrder] = useState("");
  const [parent_id, setParentId] = useState("");
  const [status, setStatus] = useState("");
  const [id, setCategoryId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Category");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [org_id, setOrgId] = useState(ORG_ID);
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [image, setImage] = useState("");
  const [imageLabel, setImageLabel] = useState("");
  const [currentImage, setCurrentImage] = useState("");
  const [currentImageDisplay, setCurrentImageDisplay] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.parent_name.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      key: "parent_id",
      title: "Category Type",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.parent_id == null || record.parent_id == '0' || record.parent_id == "" ? (
                <span>Main Category</span>
              ) : (
                <span>Sub category ({record.parent_name})</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "display_order",
      title: "Display Order",
      dataIndex: "display_order",
    },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {" "}


            <div className="flex">

              {record.status == "active" ? (
                <Switch

                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked
                  onClick={() => handleClick("inactive", record.id)}
                />
              ) : (
                <Switch

                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultunchecked
                  onClick={() => handleClick("active", record.id)}
                />
              )}

            </div>

          </>
        );
      },
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            <div className="d-flex">
              <button
                className="badge badge-pill bg-light-info mb-1 mr-2"
                style={{ border: '2px solid #dadada' }}
                onClick={() => Edit(record)}
              >
                <i class="ft-edit"></i>
              </button>
              <button
                className="badge badge-pill bg-light-danger mb-1"
                style={{ border: '2px solid #dadada' }}
                onClick={() => Delete(record.id)}
              >
                <i className="ft-trash-2"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadTableData();
    CategoryAPI.getMainCategory().then((response) => {
      setMainCategoryList(response.data.data);
    });

  }, []);

  const loadTableData = () => {
    setVisible(false);
    setLoading(true);
    CategoryAPI.getAllCategory().then((response) => {
      setTableData(response.data.data)
      setLoading(false);

    }).catch((error) => { console.error(error) });
  }

  const handleSubmit = (e) => {
    e.preventDefault();


    if (parseInt(display_order) <= 0) {
      Swal.fire("Warning", "Display order needs to be greater than 0", "warning");
    } else {

      const data = { name:name, description:description, org_id:org_id,display_order: parseInt(display_order), parent_id:parent_id, status:status, image:image, id:id };
      setConfirmLoading(true);
      if (id == "0") {

        CategoryAPI.saveNewCategory(data).then((response) => {

          if (response.data.status_code === 0) {
            Swal.fire("Success", "" + response.data.message, "success").then(function () {
              setConfirmLoading(false);
              loadTableData();
            });
          } else {
            setConfirmLoading(false);
            Swal.fire("Warning", " " + response.data.message, "warning");
          }
        }).catch((error) => {
          setConfirmLoading(false);
          Swal.fire("" + error.response.data.message, "" + error.response.data.errors[0].location.path.replace('/', "") + " - " + error.response.data.errors[0].message, "warning");
        });

      } else {

        CategoryAPI.editCategoryDetails(data).then((response) => {
          if (response.data.status_code === 0) {
            Swal.fire("Success", "" + response.data.message, "success").then(function () {
              setConfirmLoading(false);
              loadTableData();
            });
          } else {
            setConfirmLoading(false);
            Swal.fire("Warning", " " + response.data.message, "warning");
          }
        }).catch((error) => {
          setConfirmLoading(false);
          Swal.fire("Warning", "" + error.response.data.message, "warning");
          console.error(error)
        });

      }
    }
  };

  const handleNewCategoryForm = (e) => {
    setConfirmLoading(false);
    setCategoryId('0');
    setName("");
    setParentId("");
    setDisplayOrder("");
    setDescription("");
    setStatus('active');
    setModalTitle("Add New Category");
    setImageLabel("Image");
    if (image) {
      inputRef.current.value = "";
    }
    setImage("");
    $('#image-preview-area').hide();
    setVisible(true);
    setCurrentImageDisplay("none");
  };

  const Edit = (data) => {
    setConfirmLoading(false);
    setCategoryId(data.id);
    setName(data.name);
    setStatus(data.status);
    setDescription(data.description);
    setDisplayOrder(data.display_order);
    setParentId(data.parent_id == null ? "" : data.parent_id);
    setCurrentImage(data.image);
    if (image) {
      inputRef.current.value = "";
    }
    setImage("");
    setImageLabel("Replace Image");
    setModalTitle("Edit Category");
    $('#image-preview-area').show();
    setCurrentImageDisplay("");
    setVisible(true);
  };

  const encodeImageFileAsURL = async (element) => {
    let file = element.target.files[0];
    let reader = new FileReader();
    reader.onloadend = function () {
      setImage(reader.result)
    }
    reader.readAsDataURL(file);
  }


  const Delete = (deleteId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        CategoryAPI.deleteCategory(deleteId).then((response) => {
          if (response.data.status_code === 0) {
            Swal.fire("Success", "" + response.data.message, "success").then(function () {
              loadTableData();
            });
          } else {
            Swal.fire("Warning", " " + response.data.message, "warning");
          }
        }).catch((error) => {
          Swal.fire("Warning", "" + error.response.data.message, "warning");
          console.error(error)
        });
      }
    });
  };

  const handleClick = (status, categoryID) => {

    CategoryAPI.changeCategoryStatus(parseInt(categoryID), status).then((response) => {

      if (response.data.status_code == 0) {
        Swal.fire("Success", "" + response.data.message, "success");
      } else {
        Swal.fire("Warning", " " + response.data.message, "warning");
      }

      loadTableData();
    }).catch((error) => {
      Swal.fire("Warning", "" + error.response.data.message, "warning");
      console.error(error)
      loadTableData();
    });

  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Categories </h3>
        </div>
        <div className="col-4">
          <Link
          to={'/get-categories'}
          >
          <button
            type="button"
            className="btn btn-primary mb-3 d-block  float-right"
          >
            Add New<i class="ft-plus ml-2"></i>
          </button>
          </Link>
        </div>
      </div>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">

                    <div className="col-md-6">
                      <Input.Search
                        placeholder="Search here..."
                        style={{ marginBottom: 5 }}
                        value={searchedText}
                        onSearch={(value) => {
                          setSearchedText(value);
                        }}
                        onChange={(e) => {
                          setSearchedText(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="table-responsive">
                    <div className="table">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        width={600}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <TextInput
              label={"Name"}
              placeholder="Name"
              name="name"
              onchange={(e) => setName(e.target.value)}
              value={name}
              required={true}
            />
          </div>
          <div className="col-md-12">
            <TextInput
              label={"Description"}
              placeholder="Description"
              name="description"
              onchange={(e) => setDescription(e.target.value)}
              value={description}
              required={true}
            />
          </div>
          <div className="col-md-6">
            <Label label={'Main Category'} />
            <select
              className="form-control"
              name="parent_id"
              id="parent_id"
              value={parent_id}
              required
              onChange={(e) => setParentId(e.target.value)}
            >
              <option value="" selected> This is a main category</option>
              {mainCategoryList.map((item, i) => {
                return (
                  <option value={item.id} key={i}> {item.name}</option>
                );
              })}
            </select>
          </div>
          <div className="col-md-6">
            <TextInput
              label={"Display Order"}
              placeholder="Display Order"
              type={'number'}
              name="display_order"
              onchange={(e) => setDisplayOrder(e.target.value)}
              value={display_order}
              min={1}
              required={true}
            />
          </div>
          <div className="col-md-6">
            <Label label={imageLabel} required={true} />
            <input
              type="file"
              name="image"
              accept="image/*"
              max-file-size="1024"
              className="form-control"
              ref={inputRef}
              onChange={encodeImageFileAsURL}
            />
            <p className="text-muted mb-0 mt-1 mt-sm-0">
              <small>*Allowed JPG, PNG or JPEG.</small>
            </p>
          </div>
          <div className="col-md-6">
            <DropDown
              label={"Status"}
              placeholder="select status"
              name={'status'}
              value={status}
              onchange={(e) => setStatus(e.target.value)}
              list={[{ "id": "active", "name": "Active" }, { "id": "inactive", "name": "Inactive" }]}
              required={true}
            />
          </div>
          <div className="col-md-12" id="image-preview-area" style={{ display: currentImageDisplay }}>
            <h6>Current Image</h6>
            <img alt={'product-image'} className="img-fluid" width={200} src={`${IMAGE_BASE_URL}${currentImage}`} />
          </div>
        </div>
      </Modal>
    </>
  );
}
export default Category;
