import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Modal, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import TextInput from "../../components/form/TextInput";
import LocationAPI from "../../services/LocationAPIServices";
import DropDown from "../../components/form/DropDown";
import CommonFunctions from "../../utils/CommonFunctions";


function Location() {
  const [tableData, setTableData] = useState([]);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [id, setLocationId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Location");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "name",
      title: "Location",
      dataIndex: "name",
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type location"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.status == 1 ? (
                <span className="badge bg-light-success">Active</span>
              ) : (
                <span className="badge bg-light-danger">Inactive</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            <div className="d-flex">
              <button
                className="badge badge-pill bg-light-info mb-1 mr-2"
                style={{border: '2px solid #dadada'}}
                onClick={() => Edit(record)}
              >
                <i class="ft-edit"></i>
              </button>
              <button
                  className="badge badge-pill bg-light-danger mb-1"
                  style={{border: '2px solid #dadada'}}
                  onClick={() => Delete(record.id)}
              >
                <i className="ft-trash-2"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadTableData();
  }, []);

  const loadTableData =()=>{
    setVisible(false);
    setLoading(true);
    LocationAPI.getLocationList().then((response) => {
      setTableData(response.data.data)
      setLoading(false);

    }).catch((error) => { console.error(error) });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { name, status, id };
    setConfirmLoading(true);
      if(id == "0"){
        LocationAPI.saveNewLocation(data).then((response) => {
          console.log('response',response.data.status_code)
          if (response.data.status_code === 0) {
            Swal.fire("Success", ""+response.data.message, "success").then(function() {
              setConfirmLoading(false);
              loadTableData();
            });

          }else{
            setConfirmLoading(false);
            Swal.fire("Warning", " " + response.data.message, "warning");
          }
        }).catch((error) => {
          setConfirmLoading(false);
            Swal.fire("Warning", ""+error.response.data.errors[0].location.path.replace('/', "")+" - " + error.response.data.errors[0].message, "warning");
        });

      }else{
        LocationAPI.editLocation(data).then((response) => {
          if (response.data.status_code === 0) {
            Swal.fire("Success", ""+response.data.message, "success").then(function() {
              setConfirmLoading(false);
              loadTableData();
            });
          }else{
            setConfirmLoading(false);
            Swal.fire("Warning", " " + response.data.message, "warning");
          }
        }).catch((error) => {
          setConfirmLoading(false);
          Swal.fire("Warning", ""+error.response.data.message, "warning");
          console.error(error)
        });
      }
    }

  const handleNewLocationForm = (e) => {
    e.preventDefault();
    setConfirmLoading(false);
    setLocationId(0);
    setName("");
    setStatus("1");
    setModalTitle("Add New Location");
    setVisible(true);
  };

  const Edit = (data) => {
    setConfirmLoading(false);
    setLocationId(data.id);
    setName(data.name);
    setStatus(data.status);
    setModalTitle("Edit Location");
    setVisible(true);
  };

  const Delete = (deleteId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
       LocationAPI.deleteLocation(deleteId).then((response) => {
         if (response.data.status_code === 0) {
           Swal.fire("Success", ""+response.data.message, "success").then(function() {
             loadTableData();
           });
         }else{
           Swal.fire("Warning", " " + response.data.message, "warning");
         }
       }).catch((error) => {
         Swal.fire("Warning", ""+error.response.data.message, "warning");
         console.error(error)
       });
      }
    });
  };
  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Locations </h3>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-primary mb-3 d-block  float-right"
            onClick={(e) => handleNewLocationForm(e)}
          >
            Add New
          </button>
        </div>
      </div>
      <section id="simple-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="table">
                        <Table
                          loading={loading}
                          dataSource={tableData}
                          columns={columns}
                          pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                            position: ["bottomCenter"],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false) }
        onOk={(e) =>  {
          handleSubmit(e);
        }}
      >
          <TextInput
              label={"Location Name"}
              placeholder="name"
              name="name"
              onchange={(e) => setName(e.target.value)}
              value={name}
              required={true}
          />

          <DropDown
              label={"Status"}
              placeholder="select status"
              name={'status'}
              value={status}
              onchange={(e) => setStatus(e.target.value)}
              list={CommonFunctions.getCommonStatuses()}
              required={true}
          />
      </Modal>
    </>
  );
}
export default Location;
