import API from "./AxiosHeaders";
import {endPoints} from "./APIEndPoints";
import {ORG_ID} from "../AppConstant";

const saveNewBranch = async (data) => {
    return await API.commonInstance.post(endPoints.saveBranch, data);
}

const editBranchDetails = async (data) => {
    return await API.commonInstance.post(endPoints.editBranch, data);
}

const getAllBranch = async () => {
    return await API.commonInstance.get(endPoints.getAllBranch);
}

const deleteBranch = async (branchId) => {
    return await API.commonInstance.delete(endPoints.deleteBranch+`/`+branchId);
}

const getActiveBranches = async () => {
    return await API.authInstance.get(endPoints.getActiveBranches+`?org_id=`+ORG_ID);
}

const BranchAPI = {
    saveNewBranch,
    editBranchDetails,
    getAllBranch,
    deleteBranch,
    getActiveBranches
};

export default BranchAPI;