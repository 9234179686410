import API from "./AxiosHeaders";
import {endPoints} from "./APIEndPoints";


const getMasterCategories = async () => {
    return await API.commonInstance.get(endPoints.getMasterCategories);
}

const getMasterProductForOrg = async () => {
    return await API.commonInstance.get(endPoints.getMasterProductForOrg);
}

const getMasterProducts = async () => {
    return await API.commonInstance.get(endPoints.getMasterProducts);
}

const changeMasterProductStatus = async (productId,status) => {
    return await API.commonInstance.post(endPoints.changeMasterProductStatus,{ id: productId,status:status });
}

const getAllMasterCategories = async () => {
    return await API.commonInstance.get(endPoints.getAllMasterCategories);
}

const changeMasterCategoryStatus = async (categoryId,status) => {
    return await API.commonInstance.post(endPoints.changeMasterCategoryStatus,{ id: categoryId,status:status });
}

const MasterFileAPI = {
    getMasterCategories,
    getMasterProductForOrg,
    getMasterProducts,
    changeMasterProductStatus,
    getAllMasterCategories,
    changeMasterCategoryStatus
};

export default MasterFileAPI;