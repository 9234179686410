import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Modal, Input, Button ,Select, Radio, Space } from "antd";
import TextInput from "../components/form/TextInput";
import DropDown from "../components/form/DropDown";
import $ from 'jquery';
import BranchAPI from "../services/BranchAPIServices";
import StockAPI from "../services/StockAPIServices";
import Label from "../components/form/Label";


function StockAdjustment() {

    const [tableData, setTableData] = useState([]);
    const [branch_id, setBranchName] = useState("");
    const [product_id, setProductId] = useState("");
    const [product_name, setProductName] = useState("");
    const [changeCount, setChangeCount] = useState("");
    const [method, setMethod] = useState("in");
    const [note, setNote] = useState("");
    const [stockLabel, setStockLabel] = useState("");
    const [branchList, setBranchList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [branchReadonly, setBranchReadonly] = useState(false);
    const [originalProductList, setOriginalProductList] = useState([]);

    useEffect(() => {
        BranchAPI.getActiveBranches().then((response)=>{
            setBranchList(response.data.data);
        });

    }, []);

    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: 'Adjust Type',
            dataIndex: 'adjust_type',
            key: 'adjust_type',
        },
        {
            title: 'Count',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
        },
        {
            key: "action",
            title: "",
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                            <button
                                className="badge badge-pill bg-light-danger mb-1 mr-2"
                                style={{border: '2px solid #dadada'}}
                                onClick={() => Remove(record.product_id)}
                            >
                                <i class="ft-trash-2"></i>
                            </button>
                        </div>
                    </>
                );
            },
        },
    ];

    const onChange = (value) => {

        setProductId(value.value);
        setProductName(value.label);
        let stockQty ="";
        originalProductList.map((item, inx) => {
            if(item.product.id == value.value){
                stockQty = item.quantity;
                setStockLabel("Available stock : " + stockQty)
            }
        });

    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

// Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const Remove = (id) => {
        const newList = tableData.filter((item) => item.product_id !== id);
        setTableData(newList);
    };

    const onChangeMethod = (e) => {
        setMethod(e.target.value);
    };

    const changeBranch = (e) => {
        setBranchName(e.target.value);
        let itemsArray = [];
        setStockLabel("");
        setProductList([]);
        setProductId("");
        setProductName("");

        StockAPI.getBranchStockItems(e.target.value).then((response)=>{
            setOriginalProductList(response.data.data);
            response.data.data.map((item, index) => {
                itemsArray.push({"value":response.data.data[index].product.id,"label":response.data.data[index].product.title});
            });
            setProductList(itemsArray);
        });
    };

    const checkProductQty = (productId,adjustType,qty) =>{
        let stockCheck = true;
        let message = "";
        originalProductList.map((item, inx) => {
            if(item.product.id == productId){
                if((item.quantity == 0 && adjustType == 'out') || (item.quantity < qty && adjustType == 'out')){
                    stockCheck = false;
                    message = "The available stock is less than the adjust qty"
                }
            }
        });

        return {check:stockCheck,message:message};
    }

    const addItem = (data) => {

        const isFound = tableData.some(element => {
            return element.product_id === product_id;
        });
        if(!isFound){

            if(branch_id === "" || product_id === "" || parseInt(changeCount) <= 0 || changeCount == "" || note === "" ){
                 if(branch_id === ""){
                    Swal.fire("Warning", 'Please select a branch', "warning");
                }else if(parseInt(changeCount) <= 0){
                    Swal.fire("Warning", 'Please fill the all data & Change count needs to be greater than 0', "warning");
                }else{
                    Swal.fire("Warning", 'Please fill the all data.', "warning");
                }
            }else{
                let checkingResp = checkProductQty(product_id,method,changeCount);
                if(checkingResp.check){
                    setTableData([
                        ...tableData,
                        {"product_name":product_name, "product_id": product_id, "adjust_type":method,"qty":changeCount,"note":note }
                    ]);
                    setBranchReadonly(true);
                    setChangeCount("");
                    setNote("");
                    setStockLabel("");
                    setProductId("");

                }else{
                    Swal.fire("Warning", ''+checkingResp.message, "warning");
                }

            }
        }else{
            Swal.fire("Warning", 'This product has already been added.', "warning");
        }
    };



    const handleSubmit = (e) => {
        e.preventDefault();
        let adjustmentDetails = tableData;
        const data = {branch_id,adjustmentDetails}

        if(adjustmentDetails.length == 0){
            Swal.fire("Warning", "Please add at least one item.", "warning");
        }else{
            $("#submitButton").prop("disabled", true);
            StockAPI.saveNewAdjust(data).then((response) => {

                if (response.data.status_code === 0) {
                    Swal.fire("Success", ""+response.data.message, "success").then(function() {
                        window.location.replace("/adjustment-history");
                    });
                }else{
                    $("#submitButton").prop("disabled", false);
                    Swal.fire("Warning", " " + response.data.message, "warning");
                }
            }).catch((error) => {
                $("#submitButton").prop("disabled", false);
                Swal.fire(""+error.response.data.message, ""+error.response.data.errors[0].location.path.replace('/', "")+" - " + error.response.data.errors[0].message, "warning");
            });
        }

    };
    return (
        <>
            <div className="row">
                <div className="col-8">
                    <h3> Stock Adjustment </h3>
                </div>
                <div className="col-4">
                    <DropDown
                        label={"Select Branch"}
                        placeholder="select branch"
                        name={'branch_id'}
                        value={branch_id}
                        onchange={changeBranch}
                        list={branchList}
                        disabled={branchReadonly}
                    />
                </div>
            </div>
            <section>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="row g-3 align-items-center">
                                        <div className="col-md-4">
                                            <label className="form-label"> Select Product</label>
                                            <Select
                                                style={{width:'100%'}}
                                                showSearch
                                                labelInValue
                                                placeholder="Select a product"
                                                optionFilterProp="children"
                                                onChange={onChange}
                                                onSearch={onSearch}
                                                filterOption={filterOption}
                                                options={productList}
                                                value={product_id}
                                            />
                                            <label style={{color:"red"}}>{stockLabel}</label>
                                        </div>
                                        <div className="col-md-2 ">
                                            <label className="form-label"> Adjust Type</label>
                                            <Radio.Group onChange={onChangeMethod} value={method}>
                                                <Space direction="vertical">
                                                    <Radio value={"in"}>+</Radio>
                                                    <Radio value={"out"}>-</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </div>
                                        <div className="col-md-2">
                                            <TextInput
                                                label={"Count"}
                                                placeholder="Count"
                                                name="changeCount"
                                                type={"number"}
                                                onchange={(e) => setChangeCount(e.target.value)}
                                                value={changeCount}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <TextInput
                                                label={"Note"}
                                                placeholder="Note"
                                                name="note"
                                                onchange={(e) => setNote(e.target.value)}
                                                value={note}
                                            />
                                        </div>
                                        <div className="col-md-1">
                                            <button
                                                type="button"
                                                className="btn btn-primary mt-3 d-block  float-right"
                                                onClick={() => addItem()}
                                            >
                                                Add
                                            </button>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <Table columns={columns} dataSource={tableData} />
                                        </div>
                                        <div className="col-md-12">
                                            <button
                                                type="button"
                                                id={"submitButton"}
                                                className="btn btn-primary mb-3 d-block  float-right"
                                                onClick={(e) =>  { handleSubmit(e);}}
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default StockAdjustment;
