import { useNavigate } from 'react-router-dom';

function PageNotFound() {
    const history = useNavigate();

    const goBack = () => {
        history(-1);
    };
    return (
        <>
            <div style={{ background: "#dadee3" }} >
                <section id="error" class="auth-height">
                    <div class="container-fluid">
                        <div class="row full-height-vh">
                            <div class="col-12 d-flex align-items-center justify-content-center">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img src="../../../app-assets/img/gallery/error.png" alt="" class="img-fluid error-img mt-2" height="300" width="350" />
                                        <h1 class="mt-4">404 - Page Not Found!</h1>
                                        <div class="w-75 error-text mx-auto mt-4">
                                            <p>The page you are looking for might have beel removed, had it's name changed, or is temporarily unavailable.</p>
                                        </div>
                                        <button onClick={goBack} class="btn btn-warning my-2">Back To Home</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        </>
    );
}

export default PageNotFound;
