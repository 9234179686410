import { Outlet, Navigate } from "react-router-dom";
import CommonFunctions from "./CommonFunctions";

const PrivateRoutes = ({children}) => {
    let jwt = CommonFunctions.getToken();
    return (<>
        {jwt ? <Outlet/> : <Navigate to="/"/>}
    </>);
}

export default PrivateRoutes;