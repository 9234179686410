import API from "./AxiosHeaders";
import {endPoints} from "./APIEndPoints";

const getAllCustomers = async () => {
    return await API.commonInstance.get(endPoints.getAllCustomers);
}
const getCustomerDetails = async (customerId) => {
    return await API.commonInstance.get(endPoints.getSingleCustomerDetails+`/`+customerId);
}

const CustomerAPI = {
    getAllCustomers,
    getCustomerDetails
};

export default CustomerAPI;