import API from "./AxiosHeaders";
import {endPoints} from "./APIEndPoints";

const getSingleProductData = async (productId) => {
    return await API.commonInstance.get(endPoints.getProductDetails+`/`+productId);
}

const saveNewProduct = async (data) => {
    return await API.commonInstance.post(endPoints.saveNewProduct, data);
}

const editProductDetails = async (data) => {
    return await API.commonInstance.put(endPoints.editProductData, data);
}

const getAllProducts = async () => {
    return await API.commonInstance.get(endPoints.getAllProducts);
}

const deleteProduct = async (productId) => {
    return await API.commonInstance.delete(endPoints.deleteProduct+`/`+productId);
}


const changeProductStatus = async (productId,status) => {
    return await API.commonInstance.post(endPoints.changeProductStatus,{ id: productId,status:status } );
}
const quickEditProductData = async (data) => {
    return await API.commonInstance.put(endPoints.quickEditProductData, data);
}

const getProductListWithMasterIds = async (data) => {
    return await API.commonInstance.post(endPoints.getProductWithMasterIds,data);
}

const saveProductFromMaster = async (data) => {
    return await API.commonInstance.post(endPoints.saveBulkProducts,data);
}


const ProductAPI = {
    saveNewProduct,
    editProductDetails,
    getAllProducts,
    deleteProduct,
    getSingleProductData,
    changeProductStatus,
    quickEditProductData,
    getProductListWithMasterIds,
    saveProductFromMaster
};

export default ProductAPI;