import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Modal, Input, Button } from "antd";
// import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import UserAPI from "../../services/UserAPIServices";
import TextInput from "../../components/form/TextInput";
import DropDown from "../../components/form/DropDown";
import CommonFunctions from "../../utils/CommonFunctions";
import OrganisationAPI from "../../services/OrganisationAPIServices";
import AuthAPI from "../../services/AuthAPIServices";

function User() {
  const [tableData, setTableData] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [user_type, setUserType] = useState("2");
  const [status, setStatus] = useState("");
  const [userId, setUserId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New User");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [emailEditable, setEmailEditable] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [logged_user_type, setLoggedUserType] = useState("");
  const [logged_user_org, setLoggedOrgId] = useState("");
  const [org_id, setOrgID] = useState('');
  const [dropdownReadonlyStyle, setDropdownReadonlyStyle] = useState("");

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type user's name"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email"
    },
    {
      key: "organisation",
      title: "Organisation",
      render: (record) => {
        return (<>
        {record.organisation != null ? (<>{record.organisation.name}</>):(<>Global Admin</>)}
        </>)}
    },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.status === 1 ? (
                <span className="badge bg-light-success">Active</span>
              ) : (
                <span className="badge bg-light-danger">Inactive</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <button
                  className="badge badge-pill bg-light-info mb-1 mr-2"
                  style={{border: '2px solid #dadada'}}
                  onClick={() => Edit(record)}
              >
                <i class="ft-edit"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadTableData();

    AuthAPI.getLoggedAccountDetails().then((response) => {
      setLoggedUserType(response.data.data.user_type);
      setLoggedOrgId(response.data.data.org_id);
      setOrgID(response.data.data.org_id == null || response.data.data.org_id == 0 ? "" : response.data.data.org_id)
      if(response.data.data.user_type != '1'){
        setDropdownReadonlyStyle('none')
      }
    }).catch((error) => { console.error(error) });

    OrganisationAPI.getActiveOrganisation().then((response) => {
      setOrgList(response.data.data)
    }).catch((error) => { console.error(error) });

  }, []);

  const loadTableData =()=>{
    setVisible(false);
    setLoading(true);
    setOrgID(logged_user_org == null || logged_user_org == 0 ? "" : logged_user_org)
    UserAPI.getAllUsers().then((response) => {
      setTableData(response.data.data)
      setLoading(false);

    }).catch((error) => { console.error(error) });
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { name,email,user_type,status,org_id };
    
    if(userId === "0"){
      if(org_id == ""){
        Swal.fire("Warning", "Please select organisation", "warning");
      }else{
        setConfirmLoading(true);
        UserAPI.saveNewUser(data).then((response) => {

          if (response.data.status_code === 0) {
            Swal.fire("Success", ""+response.data.message, "success").then(function() {
              setConfirmLoading(false);
              loadTableData();
            });
  
          }else{
            setConfirmLoading(false);
            Swal.fire("Warning", " " + response.data.message, "warning");
          }
        }).catch((error) => {
          setConfirmLoading(false);
          Swal.fire("Warning", ""+error.response.data.errors[0].location.path.replace('/', "")+" - " + error.response.data.errors[0].message, "warning");
        });
      }
   

    }else{
      UserAPI.editUserDetails(userId,data).then((response) => {
        if (response.data.status_code === 0) {
          Swal.fire("Success", ""+response.data.message, "success").then(function() {
            setConfirmLoading(false);
            loadTableData();
          });
        }else{
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.data.message, "warning");
        }

      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", ""+error.response.data.message, "warning");
        console.error(error)
      });
    }


  };

  const handleNewUserForm = (e) => {
    e.preventDefault();
    setConfirmLoading(false);
    setUserId("0");
    setName("");
    setEmail("")
    setStatus("1");
    setModalTitle("Add New User");
    setVisible(true);
    setEmailEditable(false);
  };

  const Edit = (data) => {
    setEmailEditable(true);
    setConfirmLoading(false);
    setUserId(data.id);
    setName(data.name);
    setEmail(data.email);
    setStatus(data.status);
    console.log('data.org_id',data.org_id);
    setOrgID(data.org_id == null || data.org_id == 0 ? "" : data.org_id);
    setModalTitle("Edit User");
    setVisible(true);
  };
  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Users </h3>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-primary mb-3 d-block  float-right"
            onClick={(e) => handleNewUserForm(e)}
          >
            Add New<i class="ft-plus ml-2"></i>
          </button>
        </div>
      </div>
      <section id="simple-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="table">
                        <Table
                          loading={loading}
                          dataSource={tableData}
                          columns={columns}
                          pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                            position: ["bottomCenter"],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false) }
        onOk={(e) =>  {
          handleSubmit(e);
        }}
      >
          <TextInput
              label={"Name"}
              placeholder="name"
              name="name"
              onchange={(e) => setName(e.target.value)}
              value={name}
              required={true}
          />
          <TextInput
              label={"Email"}
              placeholder="email"
              name="email"
              onchange={(e) => setEmail(e.target.value)}
              value={email}
              readOnly={emailEditable}
              required={true}
          />
           <DropDown
            stylePointer={dropdownReadonlyStyle}
            label={"Organisation"}
            placeholder="select Organisation"
            name={'Organisation'}
            value={org_id}
            onchange={(e) => setOrgID(e.target.value)}
            list={orgList}
            required={true}
        />

        <DropDown
            label={"Status"}
            placeholder="select status"
            name={'status'}
            value={status}
            onchange={(e) => setStatus(e.target.value)}
            list={CommonFunctions.getCommonStatuses()}
            required={true}
        />

      </Modal>
    </>
  );
}
export default User;
