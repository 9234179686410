import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Input} from "antd";
import CategoryAPI from "../../services/CategoryAPIServices";
import MasterFileAPI from "../../services/MasterFileAPIServices";
import CommonFunctions from "../../utils/CommonFunctions";

function MasterCategoryOrg() {

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [orgCategoryMasterIds, setOrgCategoryMasterIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [org_id, setOrgId] = useState(CommonFunctions.getOrgId());
  const [buttonLoading, setButtonLoading] = useState(false);

  const columns = [
    {
      key: "key",
      title: "ID",
      dataIndex: "key",
    },
    {
      key: "name",
      title: "Category Name",
      dataIndex: "name",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.description.toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (value, record) => {
        return (
          <>
            {record.parent_id > 0 ? (
              <span>{value}</span>
            ) : (<>
              <b><span>{value}</span> </b> <li><i><small>Main Category</small></i></li>
            </>)}

          </>
        );
      },
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",

    },

  ];



  useEffect(() => {
    loadTableData();
  }, []);

  const loadTableData = () => {
    const org_data = { org_id: parseInt(org_id) };
    CategoryAPI.getCategoryListWithMasterIds(org_data).then((response) => {
      const masterIdFlattenedArray = response.data.data.map(obj => obj['master_id']).flat();
      setOrgCategoryMasterIds(masterIdFlattenedArray);
      setSelectedRowKeys(masterIdFlattenedArray);
    }).catch((error) => { console.error(error) });

    setLoading(true);
    MasterFileAPI.getMasterCategories().then((response) => {
      setTableData(response.data.data)
      setLoading(false);

    }).catch((error) => { console.error(error) });
  }

  const handleSubmit = (e) => {
    const uniqueCategories = selectedRows.slice(orgCategoryMasterIds.length);
    console.log('element', uniqueCategories);
    const nameArray = uniqueCategories.map(obj => obj['name']).flat();
    const jsonString = JSON.stringify(nameArray, null, 2);

    if (uniqueCategories.length == 0) {
      Swal.fire("Warning", "Please select the category", "warning");
    } else {
      Swal.fire({
        title: "Are you sure the below categories will copied?",
        text: jsonString,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Copy it!"
      }).then((result) => {
        if (result.isConfirmed) {
          const copy_data = { categories: uniqueCategories.sort((a, b) => a.parent_id - b.parent_id) }
          setButtonLoading(true);
          CategoryAPI.saveCategoryFromMaster(copy_data).then((response) => {
            if (response.data.status_code === 0) {
              Swal.fire("Success", "" + response.data.message, "success").then(function () {
                loadTableData();
              });
            } else {
              Swal.fire("Warning", " " + response.data.message, "warning");
            }
            setButtonLoading(false);
          }).catch((error) => {
            Swal.fire("Warning", "" + error.response.data.message, "warning");
            setButtonLoading(false);
            console.error(error)
          });
          
        }
      });

    }
  };


  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    console.log('selectedRows', selectedRows);

    // setSelectedRows(prevItems => [...prevItems, lastElement]);
    setSelectedRows(selectedRows);
  };

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: orgCategoryMasterIds.includes(record.key),
      key: record.key,
    }),
    // onChange: (selectedRowKeys, selectedRows) => {

    // },
    // onSelect: (record, selected, selectedRows) => {
    //   console.log('selected',selected);
    //   console.log('record',record, selectedRows);
    // },
    // onSelectAll: (selected, selectedRows, changeRows) => {
    //   console.log('selected2',selected);
    //   console.log('changeRows',selectedRows);
    //   console.log(selected, selectedRows, changeRows);
    // },
  };


  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3> Get Categories </h3>
        </div>

      </div>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <Input.Search
                        placeholder="Search here..."
                        style={{ marginBottom: 5 }}
                        value={searchedText}
                        onSearch={(value) => {
                          setSearchedText(value);
                        }}
                        onChange={(e) => {
                          setSearchedText(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 text-right mb-2">
                    <button
                      className="btn btn-primary mr-2 mb-3 float-right"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      disabled={buttonLoading}
                    >
                      {buttonLoading ? (
                        <>
                          <i className="ft-refresh-ccw"> </i>
                          <span>Submitting...</span>
                        </>
                      ):(<><span> Save to Categories</span>{" "}</>)}
              
                    </button>
                  </div>
                  </div>
                  <div className="table-responsive">
            

                    <div className="table">
                      <Table
                        loading={loading}
                        columns={columns}
                        rowSelection={{
                          ...rowSelection,
                        }}
                        dataSource={tableData}
                        pagination={{
                          pageSize: 10,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
export default MasterCategoryOrg;
