import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Modal, Input, Button, TimePicker,Switch } from "antd";
import { SearchOutlined,  CheckOutlined, CloseOutlined, } from "@ant-design/icons";
import $ from 'jquery';
import TextInput from "../../components/form/TextInput";
import BranchAPI from "../../services/BranchAPIServices";
import DropDown from "../../components/form/DropDown";
import CommonFunctions from "../../utils/CommonFunctions";
import Label from "../../components/form/Label";
import {DEFAULT_END_TIME, DEFAULT_START_TIME} from "../../AppConstant";
import dayjs from "dayjs";

function Branch() {
  const [tableData, setTableData] = useState([]);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [id, setBranchId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Branch");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dayArray, setDayArray] = useState(['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [switchValues, setSwitchValues] = useState([
      { id: 0, checked: false, start:"", end: ""},
    { id: 1, checked: false, start:"", end: ""},
    { id: 2, checked: false, start:"", end: ""},
    { id: 3, checked: false, start:"", end: ""},
    { id: 4, checked: false, start:"", end: ""},
    { id: 5, checked: false, start:"", end: ""},
    { id: 6, checked: false, start:"", end: ""}
]);
  const format = 'HH:mm';

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "name",
      title: "Branch",
      dataIndex: "name",
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type branch"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.status == 1 ? (
                <span className="badge bg-light-success">Active</span>
              ) : (
                <span className="badge bg-light-danger">Inactive</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            <div className="d-flex">
              <button
                  className="badge badge-pill bg-light-info mb-1 mr-2"
                  style={{border: '2px solid #dadada'}}
                  onClick={() => Edit(record)}
              >
                <i className="ft-edit"></i>
              </button>
              <button
                  className="badge badge-pill bg-light-danger mb-1"
                  style={{border: '2px solid #dadada'}}
                  onClick={() => Delete(record.id)}
              >
                <i className="ft-trash-2"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadTableData();
  }, []);


  const loadTableData =()=>{
    setVisible(false);
    setLoading(true);
    BranchAPI.getAllBranch().then((response) => {
      setTableData(response.data.data)
      setLoading(false);

    }).catch((error) => { console.error(error) });
  }

  const handleSetStartTime =()=>{
    let isChecked = $('#startTimeChange').is(':checked')

    if (isChecked) {
      dayArray.map((item, index) => {
        onChangeStartTime(index,DEFAULT_START_TIME);
        // $("#startTime"+index).val(DEFAULT_START_TIME);
      });

    }else{
      dayArray.map((dayList, index) => {
        onChangeStartTime(index,null);
        // $("#startTime"+index).val('')
      });
    }
  }

  const handleSetEndTime =()=>{
   let checked =  $('#endTimeChange').is(':checked')
    if (checked) {
      dayArray.map((item, index) => {
        onChangeEndTime(index,DEFAULT_END_TIME)
        // $("#endTime"+index).val(DEFAULT_END_TIME);
      });

    }else{
      dayArray.map((dayList, index) => {
        onChangeEndTime(index,null)
        // $("#endTime"+index).val('')
      });
    }
  }

  const onChangeStartTime  = (index,time) => {
    setSwitchValues((prevValues) =>
        prevValues.map((switchObj) =>
            switchObj.id === index ? { ...switchObj, start: time != null ? dayjs(time,format):'' } : switchObj
        )
    );
    $("#startTime"+index).val(time != null ? dayjs(time,format) :'');
  };

  const onChangeEndTime  = (index,time) => {
    setSwitchValues((prevValues) =>
        prevValues.map((switchObj) =>
            switchObj.id === index ? { ...switchObj, end: time != null ? dayjs(time,format) :''} : switchObj
        )
    );
    $("#endTime"+index).val(time != null? dayjs(time,format) : '');
  };

  const onChangeOffDay  = (index,checked) => {

    setSwitchValues((prevValues) =>
        prevValues.map((switchObj) =>
            switchObj.id === index ? { ...switchObj, checked: checked } : switchObj
        )
    );

    $("#isOff"+index).val(checked);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    let timeValidation = true;
    let inputValidation = true;
    let operation_times = '{';
    dayArray.map((day, index) => {

      if($("#isOff"+index).val() == 'true'){
        operation_times += '"'+day+'":"Off",';
      }else{
        if($("#startTime"+index).val() == "" || $("#startTime"+index).val() == null){
          Swal.fire("Warning", "Please enter "+day+ " start time.", "warning");
          timeValidation = false;
        }else if($("#endTime"+index).val() == "" || $("#endTime"+index).val() == null){
          Swal.fire("Warning", "Please enter "+day+ " end time.", "warning");
          timeValidation = false;
        }else{
          operation_times += '"'+day+'":{"start":"'+$("#startTime"+index).val()+'","end":"'+$("#endTime"+index).val()+'"},';
        }
      }
    })
    operation_times = operation_times.slice(0, -1);
    operation_times += '}';

    if(name == "" || name == null){
      Swal.fire("Warning", "Please enter branch name", "warning");
      inputValidation = false;
    }else if(address == "" || address == null){
      Swal.fire("Warning", "Please enter address", "warning");
      inputValidation = false;
    }
    const data = { name, address, status, id, operation_times };

    if(id == "0" && timeValidation && inputValidation){
      setConfirmLoading(true);
      BranchAPI.saveNewBranch(data).then((response) => {

        if (response.data.status_code === 0) {
          Swal.fire("Success", ""+response.data.message, "success").then(function() {
            setConfirmLoading(false);
            loadTableData();
          });

        }else{
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", ""+error.response.data.message, "warning");
      });
    }else if(timeValidation && inputValidation){
      setConfirmLoading(true);
      BranchAPI.editBranchDetails(data).then((response) => {
        if (response.data.status_code === 0) {
          Swal.fire("Success", ""+response.data.message, "success").then(function() {
            setConfirmLoading(false);
            loadTableData();
          });
        }else{
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", ""+error.response.data.message, "warning");
        console.error(error)
      });
    }
  };

  const handleNewBranchForm = (e) => {
    e.preventDefault();
    setConfirmLoading(false);
    setBranchId("0");
    setName("");
    setAddress("");
    setStatus("1");
    setModalTitle("Add New Branch");
    setVisible(true);

    if($('#startTimeChange').is(':checked')){
      document.getElementById('startTimeChange').checked = false;
    }

    if($('#endTimeChange').is(':checked')){
      document.getElementById('endTimeChange').checked = false;
    }

    dayArray.map((dayList, index) => {
      onChangeEndTime(index,null);
      onChangeStartTime(index,null)
      onChangeOffDay(index,false);
    });
  };

  const Edit = (data) => {
    setConfirmLoading(false);
    setBranchId(data.id);
    setName(data.name);
    setStatus(data.status);
    setModalTitle("Edit Branch");
    setAddress(data.address);
    setVisible(true);

    if($('#startTimeChange').is(':checked')){
      document.getElementById('startTimeChange').checked = false;
    }
    if($('#endTimeChange').is(':checked')){
      document.getElementById('endTimeChange').checked = false;
    }
    const editDayArray = Object.keys(JSON.parse(data.operation_times)).map((key) => [key, JSON.parse(data.operation_times)[key]]);

    dayArray.map((dayList, index) => {

      if(editDayArray[index][0] == dayList){

        if(editDayArray[index][1] == "Off"){
          onChangeOffDay(index,true);
          onChangeStartTime(index,null);
          onChangeEndTime(index,null)
        }else{
          onChangeOffDay(index,false);
          onChangeStartTime(index,editDayArray[index][1].start);
          onChangeEndTime(index,editDayArray[index][1].end)
        }

      }

    });

  };

  const Delete = (deleteId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        BranchAPI.deleteBranch(deleteId).then((response) => {
          if (response.data.status_code === 0) {
            Swal.fire("Success", ""+response.data.message, "success").then(function() {
              loadTableData();
            });
          }else{
            Swal.fire("Warning", " " + response.data.message, "warning");
          }
        }).catch((error) => {
          Swal.fire("Warning", ""+error.response.data.message, "warning");
          console.error(error)
        });
      }
    });
  };
  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Branches </h3>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-primary mb-3 d-block  float-right"
            onClick={(e) => handleNewBranchForm(e)}
          >
            Add New <i class="ft-plus ml-2"></i>
          </button>
        </div>
      </div>
      <section id="simple-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="table">
                        <Table
                          loading={loading}
                          dataSource={tableData}
                          columns={columns}
                          pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                            position: ["bottomCenter"],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        width={700}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false) }
        onOk={(e) =>  {
          handleSubmit(e);
        }}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="row">
            <div className="col-md-8">
              <TextInput
                  label={"Branch Name"}
                  placeholder="name"
                  name="name"
                  onchange={(e) => setName(e.target.value)}
                  value={name}
                  required={true}
              />
            </div>
            <div className="col-md-4">
              <DropDown
                  label={"Status"}
                  placeholder="select status"
                  name={'status'}
                  value={status}
                  onchange={(e) => setStatus(e.target.value)}
                  list={CommonFunctions.getCommonStatuses()}
                  required={true}
              />
            </div>

            <div className="col-12">
              <TextInput
                  label={"Address"}
                  placeholder="Address"
                  name="address"
                  onchange={(e) => setAddress(e.target.value)}
                  value={address}
                  required={true}
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">
                Day <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="col-md-3">
              <Label label={'Start'} required={true}/><br/>
              <input
                  type="checkbox"
                  id="startTimeChange"
                  value="1"
                  onClick={(e)=>handleSetStartTime()}
              />
                <span> Set default {DEFAULT_START_TIME} </span>
            </div>
            <div className="col-md-3">
              <Label label={'End'} required={true}/>
              <br/>
              <input
                  type="checkbox"
                  id="endTimeChange"
                  value="1"
                  onClick={handleSetEndTime}
              />
              <span> Set default {DEFAULT_END_TIME} </span>

            </div>
            <div className="col-md-2"> <Label label={'Is off Day'}></Label></div>

            {dayArray.map((day,index)=> (
                <>
                  <div className="col-md-4 mb-1">
                    <input type="text" className="form-control" id={"day"+index}  value={day} readOnly={true}/>
                  </div>
                  <div className="col-md-3">
                      <TimePicker id={"startTime"+index} format={format} value={switchValues[index].start} onChange={(time) => onChangeStartTime(index,time)}
                                 />
                  </div>
                  <div className="col-md-3">
                      <TimePicker format={format} id={"endTime"+index} value={switchValues[index].end}  onChange={(time) => onChangeEndTime(index,time)} />
                  </div>
                  <div className="col-md-1">
                    <Switch
                        // key={index}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultUnChecked
                        id={"isOff"+index}
                        checked={switchValues[index].checked}
                        value={switchValues[index].checked}
                        onChange={(checked) => onChangeOffDay(index,checked)}

                    />
                  </div>
                </>
            ))}
          </div>
        </form>
      </Modal>
    </>
  );
}
export default Branch;
