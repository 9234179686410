import API from "./AxiosHeaders";
import {endPoints} from "./APIEndPoints";

const saveNewCategory = async (data) => {
    return await API.commonInstance.post(endPoints.saveCategory, data);
}

const editCategoryDetails = async (data) => {
    return await API.commonInstance.put(endPoints.editCategory, data);
}

const getMainCategory = async () => {
    return await API.commonInstance.get(endPoints.getMainCategory);
}

const getAllCategory = async () => {
    return await API.commonInstance.get(endPoints.getAllCategory);
}

const deleteCategory = async (categoryId) => {
    return await API.commonInstance.delete(endPoints.deleteCategory+`/`+categoryId);
}

const getActiveCategoryList = async () => {
    return await API.commonInstance.get(endPoints.activeCategoryList);
}

const changeCategoryStatus = async (categoryId,status) => {
    return await API.commonInstance.post(endPoints.changeCategoryStatus,{ id: categoryId,status:status } );
}

const getCategoryListWithMasterIds = async (data) => {
    return await API.commonInstance.post(endPoints.getCategoryWithMasterIds,data);
}

const saveCategoryFromMaster = async (data) => {
    return await API.commonInstance.post(endPoints.saveBulkCategory,data);
}

const CategoryAPI = {
    saveNewCategory,
    editCategoryDetails,
    getAllCategory,
    getMainCategory,
    deleteCategory,
    getActiveCategoryList,
    changeCategoryStatus,
    getCategoryListWithMasterIds,
    saveCategoryFromMaster
    
};

export default CategoryAPI;