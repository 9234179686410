import {useState} from "react";
import Swal from "sweetalert2";
import commonFunctions from "../utils/CommonFunctions";
import {Link} from "react-router-dom";

function Navbar() {
  const [username, setUsername] = useState(localStorage.getItem("foxa_up_name"));

  const logoutFunction = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to log out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!"
    }).then((result) => {
      if (result.isConfirmed) {
        commonFunctions.removeToken();
        window.location.replace("/");
      }
    });
  };

  return (
      <nav className="navbar navbar-expand-lg navbar-light header-navbar navbar-fixed" >
        <div className="container-fluid navbar-wrapper">
          <div className="navbar-header d-flex">
            <div className="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center" data-toggle="collapse"><i className="ft-menu font-medium-3"></i></div>
            <ul className="navbar-nav">
            </ul>
          </div>
          <div className="navbar-container">
            <div className="collapse navbar-collapse d-block" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="dropdown nav-item mr-1"><a className="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2" href="javascript:;" data-toggle="dropdown">
                  <div className="user d-md-flex d-none mr-2"><span className="text-right">{username}</span>
                    <span className="text-right text-muted font-small-3">Available</span></div><img className="avatar" src="../../../app-assets/img/profile/1662712051.png" alt="avatar" height="35" width="35" />
                </a>
                  <div className="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2">

                    <Link to={`/change-password`} className="dropdown-item">
                      <div className="d-flex align-items-center"><i className="ft-edit mr-2"></i><span>Change Password</span></div>
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" onClick={(e) => {logoutFunction()}} to={`#`}>
                      <div className="d-flex align-items-center"><i className="ft-power mr-2"></i><span>Logout</span></div>
                    </Link>
                  </div>
                </li>

                <li className="nav-item d-none d-lg-block mr-2 mt-1">
                                 <button
                                   className="d-flex align-items-center"
                                   style={{ background: "none", border: "none" }}
                                   type="button"
                                   onClick={(e) => {
                                      logoutFunction()
                                   }}
                                 >
                                   <i className="ft-power mr-2"></i>
                                 </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
  );
}

export default Navbar;
