import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Modal, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {Link} from "react-router-dom";
import CustomerAPI from "../services/CustomerAPIServices";
import moment from "moment/moment";

function Customer() {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            key: "name",
            title: "Name",
            dataIndex: "first_name",
            filterMode: "tree",
            filterSearch: true,
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                             }) => {
                return (
                    <>
                        <Input
                            autoFocus
                            placeholder="Type customer name"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropDown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        ></Input>
                        <Button
                            onClick={() => {
                                confirm();
                            }}
                            type="primary"
                        >
                            Search
                        </Button>
                    </>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.first_name
                    .toLowerCase()
                    .includes(value.toLowerCase());
            },
        },
        {
            key: "email",
            title: "Email",
            dataIndex: "email"
        },
        {
            key: "phone_number",
            title: "Phone Number",
            dataIndex: "phone_number"
        },
        {
            key: "created_at",
            title: "Registered Date",
            render: (record) => {
                return (
                    <>
                        <span>{moment(record.created_at).format('DD/MM/YYYY')}</span>
                    </>
                );
            },
        },
        {
            key: "status",
            title: "Status",
            render: (record) => {
                return (
                    <>
                        {" "}
                        <div className="flex">
                            {record.status === 1 ? (
                                <span className="badge bg-light-success">Active</span>
                            ) : (
                                <span className="badge bg-light-danger">Inactive</span>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            key: "action",
            title: "",
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                            <Link to={`/customer/${record.id}`}>
                                <button className="badge badge-pill bg-light-secondary mb-1 mr-2"
                                        style={{border: '2px solid #dadada'}}>
                                    <i class="ft-eye"></i>
                                </button>
                            </Link>
                        </div>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        loadTableData();
    }, []);

    const loadTableData =()=>{

        setLoading(true);
        CustomerAPI.getAllCustomers().then((response) => {
            setTableData(response.data.data)
            setLoading(false);

        }).catch((error) => { console.error(error) });
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h3> Customers </h3>
                </div>
            </div>
            <section id="simple-table">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div className="table">
                                            <Table
                                                loading={loading}
                                                dataSource={tableData}
                                                columns={columns}
                                                pagination={{
                                                    pageSize: 10,
                                                    showSizeChanger: true,
                                                    position: ["bottomCenter"],
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Customer;
