import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Input, Button, Form, InputNumber, Popconfirm, Typography, Switch, Select } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ProductAPI from "../../services/ProductAPIServices";
import CategoryAPI from "../../services/CategoryAPIServices";
import EditableSelection from "../../components/EditableSelection";
import { CSVLink } from "react-csv";
import MasterFileAPI from "../../services/MasterFileAPIServices";

function MasterProduct() {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    const [categoryList, setCategoryList] = useState([]);
    const [searchedText, setSearchedText] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [exportFileName, setExportFileName] = useState("");

    const columns = [
        {
            key: "title",
            title: "Product Name",
            dataIndex: "title",
            filteredValue: [searchedText],
            onFilter: (value, record) => {
                return (
                    record.title.toLowerCase().includes(value.toLowerCase()) ||
                    record.master_category.name.toLowerCase().includes(value.toLowerCase()) ||
                    record.price.toLowerCase().includes(value.toLowerCase())
                );
            },
            editable: true,
        },
        {
            key: "master_category_id",
            title: "Category",
            dataIndex: ['master_category', 'name'],
            editable: true,

        },
        {
            key: "price",
            title: "Price",
            dataIndex: "price",
            editable: true,
        },
        {
            key: "status",
            title: "Visibility",
            render: (record) => {
                return (
                    <>
                        {" "}
                        <div className="flex">

                            {record.status == "1" ? (
                                <Switch
                                    disabled={editingKey !== '' ? true : false}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                    onClick={() => handleClick("0", record.id)}
                                />
                            ) : (
                                <Switch
                                    disabled={editingKey !== '' ? true : false}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultunchecked
                                    onClick={() => handleClick("1", record.id)}
                                />
                            )}

                        </div>
                    </>
                );
            },

        },
        // {
        //     key: "action",
        //     title: "",

        //     render: (_, record) => {
        //         const editable = isEditing(record);
        //         return editable ? (
        //             <span>
        //                 <Typography.Link
        //                     onClick={() => saveProduct(record.id)}
        //                     style={{
        //                         marginRight: 8,
        //                     }}
        //                 >
        //                     <button
        //                         className="badge badge bg-light-success"
        //                         style={{ border: '0' }}

        //                     >
        //                         <i class="ft-check"></i>
        //                     </button>
        //                 </Typography.Link>
        //                 <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
        //                     <a><button
        //                         className="badge badge bg-light-danger"
        //                         style={{ border: '0' }}

        //                     >
        //                         <i class="ft-x"></i>
        //                     </button></a>
        //                 </Popconfirm>
        //             </span>
        //         ) : (<>
        //             <div className="d-flex flex-column align-items-center justify-content-between" >
        //                 <div style={{ marginBottom: '4px' }}>
        //                     <Typography.Link disabled={editingKey !== ''} onClick={() => editProduct(record)}>
        //                         <button
        //                             className="badge badge bg-light-warning"
        //                             style={{ border: '0' }}

        //                         >
        //                             Quick Edit
        //                         </button>
        //                     </Typography.Link>
        //                 </div>
        //                 <div>

        //                     <Link to={`/product-edit/${record.id}`} style={{ pointerEvents: editingKey !== '' ? 'none' : '' }} disabled={editingKey !== '' ? true : false} >
        //                         <button
        //                             disabled={editingKey !== '' ? true : false}
        //                             className="badge badge-pill bg-light-info mr-2"
        //                             style={{ border: '2px solid #dadada' }}
        //                         >
        //                             <i class="ft-edit"></i>
        //                         </button>
        //                     </Link>
        //                     <button
        //                         disabled={editingKey !== '' ? true : false}
        //                         className="badge badge-pill bg-light-danger"
        //                         style={{ border: '2px solid #dadada' }}
        //                         onClick={() => Delete(record.id)}
        //                     >
        //                         <i className="ft-trash-2"></i>
        //                     </button>
        //                 </div>
        //             </div>
        //         </>);
        //     },
        // },
    ];

    const handleCellChange = (key, dataIndex) => value => {
        const newData = [...tableData];
        const index = newData.findIndex(item => key === item.key);
        if (index > -1) {
            newData[index][dataIndex] = value;
            setTableData(newData);
        }
    };

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        const inputNode = inputType === 'number' ? <InputNumber /> : inputType == 'select' ? <EditableSelection editable={editing} value={dataIndex} onChange={handleCellChange(record.key, 'category_id')} list={categoryList} /> : <Input />;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };


    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.key === 'category_id' ? 'select' : col.key === 'price' ? 'number' : 'text',
                dataIndex: col.key,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });


    const isEditing = (record) => record.id === editingKey;
    const editProduct = (record) => {
        form.setFieldsValue({
            title: '',
            price: '',
            category_id: '',
            ...record,
        });
        setEditingKey(record.id);
    };

    /*****  editing function ***********/
    const cancel = () => {
        setEditingKey('');
    };
    const saveProduct = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...tableData];
            const index = newData.findIndex((item) => key === item.id);


            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setTableData(newData);

                let quick_edit = { id: newData[index].id, category_id: parseInt(newData[index].category_id), title: newData[index].title, price: parseFloat(newData[index].price).toFixed(2) }
                ProductAPI.quickEditProductData(quick_edit).then((response) => {
                    if (response.data.status_code === 0) {
                        Swal.fire("Success", "" + response.data.message, "success").then(function () {
                            loadTableData();
                        });
                    } else {
                        Swal.fire("Warning", " " + response.data.message, "warning");
                    }
                }).catch((error) => {

                    Swal.fire("Warning", "" + error.response.data.message, "warning");

                });
                setEditingKey('');

            } else {
                newData.push(row);
                setTableData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    useEffect(() => {
        setLoading(true);
        loadTableData();
        MasterFileAPI.getMasterCategories().then((response) => {
            setCategoryList(response.data.data);
        });

    }, []);

    const loadTableData = () => {

        setLoading(true);
        MasterFileAPI.getMasterProducts().then((response) => {
            setTableData(response.data.data)

            if (searchedText == "") { setFilteredData(response.data.data) };
            setLoading(false);

        }).catch((error) => { console.error(error) });
    }
    const Delete = (deleteId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                ProductAPI.deleteProduct(deleteId).then((response) => {
                    if (response.data.status_code === 0) {
                        Swal.fire("Success", "" + response.data.message, "success").then(function () {
                            loadTableData();
                        });
                    } else {
                        Swal.fire("Warning", " " + response.data.message, "warning");
                    }
                }).catch((error) => {
                    Swal.fire("Warning", "" + error.response.data.message, "warning");
                    console.error(error)
                });
            }
        });
    };

    const handleClick = (status, productId) => {

        MasterFileAPI.changeMasterProductStatus(parseInt(productId), parseInt(status)).then((response) => {

            if (response.data.status_code == 0) {
                Swal.fire("Success", "" + response.data.message, "success");
            } else {
                Swal.fire("Warning", " " + response.data.message, "warning");
            }

            loadTableData();
        }).catch((error) => {
            Swal.fire("Warning", "" + error.response.data.message, "warning");
            console.error(error)
            loadTableData();
        });

    };

    useEffect(() => {
        if (searchedText) {
            const filteredDataSource = tableData.filter(item =>
                item.title.toLowerCase().includes(searchedText.toLowerCase()) ||
                item.master_category.name.toLowerCase().includes(searchedText.toLowerCase()) ||
                item.price.toLowerCase().includes(searchedText.toLowerCase())
            );

            setFilteredData(filteredDataSource);
        } else {
            setFilteredData(tableData);
        }

    }, [searchedText]);

    const handleExport = () => {

        setExportFileName(searchedText);
        console.log('filteredData.length', filteredData);
        let dataArrayset = [["id", "title", "description", "category", "category_id", "price", "discount", "discount_start_date", "discount_end_date", "status", "created_at"]];
        for (var i = 0; i < filteredData.length; i++) {
            var newRow = [filteredData[i].id, filteredData[i].title, filteredData[i].master_category.name, filteredData[i].category_id, filteredData[i].price, filteredData[i].discount, filteredData[i].discount_start_date, filteredData[i].discount_end_date, filteredData[i].status, filteredData[i].created_at]
            dataArrayset.push(newRow);
        }
      

        setCsvData(dataArrayset);
    };

    return (
        <>
            <div className="row">
                <div className="col-8">
                    <h3> Products </h3>
                </div>
                <div className="col-4">
                    <Link to="/product-add">
                        <button
                            type="button"
                            className="btn btn-primary mb-3 d-block float-right">
                            Add Product
                        </button>
                    </Link>
                </div>
            </div>
            <section id="simple-table">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="row">

                                        <div className="col-md-6">
                                            <Input.Search
                                                placeholder="Search here..."
                                                style={{ marginBottom: 5 }}
                                                value={searchedText}
                                                onSearch={(value) => {
                                                    setSearchedText(value);
                                                }}
                                                onChange={(e) => {
                                                    setSearchedText(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 text-right">

                                            <Button>
                                                <CSVLink
                                                    data={csvData}
                                                    filename={`${exportFileName}.csv`}
                                                    onClick={(event, done) => {
                                                        handleExport();
                                                    }}
                                                >
                                                    <i class="ft-download"></i> Product CSV
                                                </CSVLink>
                                            </Button>

                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <div className="table">
                                            <Form form={form} component={false}>
                                                <Table
                                                    components={{
                                                        body: {
                                                            cell: EditableCell,
                                                        },
                                                    }}

                                                    loading={loading}
                                                    dataSource={tableData}
                                                    columns={mergedColumns}
                                                    pagination={{
                                                        pageSize: 10,
                                                        showSizeChanger: true,
                                                        position: ["bottomCenter"],
                                                    }}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default MasterProduct;
