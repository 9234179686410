const setToken = (token, name, userId,org_id) => {
    localStorage.setItem("foxa_up_access_token", token);
    localStorage.setItem("foxa_up_name", name);
    localStorage.setItem("foxa_up_user_id", userId);
    localStorage.setItem("foxa_up_org_id", org_id);
}

const getToken = () => {
    let token = localStorage.getItem("foxa_up_access_token");
    return token;
}

const getAuthorizationHeader = () => {
    let header = "Bearer " + localStorage.getItem("foxa_up_access_token");
    return header;
}

const getUserId = () =>  {
    let id = localStorage.getItem("foxa_up_user_id");
    return id;
}

const removeToken = () => {
    localStorage.removeItem("foxa_up_access_token");
    localStorage.removeItem("foxa_up_user_id");
    localStorage.removeItem("foxa_up_name");
}

const getCommonStatuses = () =>{
    return [{"id":"1","name":"Active"},{"id":"0","name":"Inactive"}]
}

const getOrderStatuses = () => {
    return [{ 'id': 'ordered', 'name': 'Ordered'},
        { 'id': 'processing', 'name': 'Processing'},
        { 'id': 'shipped', 'name': 'Shipped'},
        { 'id': 'delivered', 'name': 'Delivered' },
        { 'id': 'canceled', 'name': 'Canceled' },
        { 'id': 'failed', 'name': 'Failed' }]
}

const getOrgId = () => {
    let org_id = localStorage.getItem("foxa_up_org_id");
    return org_id;
}

const CommonFunctions = {
    setToken,
    getToken,
    getAuthorizationHeader,
    removeToken,
    getUserId,
    getCommonStatuses,
    getOrderStatuses,
    getOrgId
}

export default CommonFunctions;
