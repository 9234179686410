import API from "./AxiosHeaders";
import {endPoints} from "./APIEndPoints";


const getAccessMenuRoutes = async () => {
    return await API.commonInstance.get(endPoints.getAccessMenus);
}

const checkAccessRoute = async (data) => {
    return await API.commonInstance.post(endPoints.getAccessMenus,data);
}

const RoutesAPI = {
    getAccessMenuRoutes,
    checkAccessRoute,
};

export default RoutesAPI;