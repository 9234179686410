import React, { useState, useEffect } from "react";
import TextInput from "../components/form/TextInput";
import {useParams} from "react-router-dom";
import AuthAPI from "../services/AuthAPIServices";
import Swal from "sweetalert2";


function ResetPassword() {
    const { token, email } = useParams();
    const [new_password, setNewPassword] = useState("");
    const [confirmed_new_password, setConfirmedNewPassword] = useState("");

    function resetPasswordSubmit(event) {

        if(new_password != confirmed_new_password || new_password.length < 8){
            if (new_password.length < 8) {
                Swal.fire("Warning", "Password must be at least 8 characters...!", "warning");
            } else if (new_password != confirmed_new_password) {
                Swal.fire("Warning", "New password and confirm password should match", "warning");
            }
        }else{
            const data = {
                email,
                token,
                new_password
            };

            AuthAPI.resetPasswordApi(data).then((response) => {
                if (response.data.status_code == 0) {
                    Swal.fire("Success", ""+response.data.message, "success").then(function() {
                        window.location.replace("/");
                    });
                } else {
                    Swal.fire("Warning", "" + response.data.message, "warning");
                }
            }).catch((error) => {
                Swal.fire("Warning", ""+error.response.data.message, "warning");
            });

        }
    }

    return (
        <div className="content-wrapper" style={{background:"#dadee3"}}>
            <section id="forgot-password" className="auth-height">
                <div className="row full-height-vh m-0 d-flex align-items-center justify-content-center">
                    <div className="col-md-7 col-12">
                        <div className="card overflow-hidden">
                            <div className="card-content">
                                <div className="card-body auth-img">
                                    <div className="row m-0">
                                        <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                                            <img
                                                src="../../../app-assets/img/gallery/forgot.png"
                                                alt=""
                                                className="img-fluid"
                                                width="260"
                                                height="230"
                                            />
                                        </div>
                                        <div className="col-lg-6 col-md-12 px-4 py-3">
                                            <h4 className="mb-2 card-title">Reset Password</h4>
                                            <div className="form-group">
                                            <TextInput
                                                label={'New Password'}
                                                placeholder={'Enter new password'}
                                                type={'password'}
                                                value={new_password}
                                                onchange={(e) => setNewPassword(e.target.value)}
                                                required={true}
                                            />
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label={'Retype New Password'}
                                                    placeholder={'Re-Enter new password'}
                                                    type={'password'}
                                                    value={confirmed_new_password}
                                                    onchange={(e) => setConfirmedNewPassword(e.target.value)}
                                                    required={true}
                                                />
                                            </div>

                                            <div className="d-flex flex-sm-row flex-column justify-content-between">

                                                <button type="button" className="btn btn-primary"  onClick={resetPasswordSubmit}>
                                                    Change
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default ResetPassword;
