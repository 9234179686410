import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Modal, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import OrderAPI from "../services/OrderAPIServices";
import moment from "moment";
import DropDown from "../components/form/DropDown";
import commonFunctions from "../utils/CommonFunctions";

function Order() {
    const [tableData, setTableData] = useState([]);
    const [orderStatusMVisible, setOrderStatusMVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orderNewStatus, setOrderNewStatus] = useState("0");
    const [orderId, setOrderId] = useState(0);
    const [statusList, setStatusList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [searchedText, setSearchedText] = useState("");

    const columns = [
        {
            key: "id",
            title: "#Order Ref",
            filteredValue: [searchedText],
            onFilter: (value, record) => {
                return (
                    record.customer.name.toLowerCase().includes(value.toLowerCase()) ||
                    record.order_ref.toLowerCase().includes(value.toLowerCase()) ||
                    record.pay_method.toLowerCase().includes(value.toLowerCase()) ||
                    record.status.toLowerCase().includes(value.toLowerCase())
                );
            },
            render: (record) => {
                return (
                    <>
                        {" "}
                        <div className="flex">
                            <Link to={`/order/${record.id}`}>
                                #{record.order_ref}
                            </Link>
                        </div>
                    </>
                );
            },
        },
        {
            key: "name",
            title: "Customer",
            dataIndex: ["customer", 'name'],
        },
        {
            key: "pay_method",
            title: "Payment Method",
            dataIndex: "pay_method"
        },
        {
            key: "created_at",
            title: "Ordered Date",
            render: (record) => {
                return (
                    <>
                        <span>{moment(record.created_at).format('DD/MM/YYYY')}</span>
                    </>
                );
            },
        },
        {
            key: "net_amount",
            title: "Net Amount",
            dataIndex: "net_amount"
        },
        {
            key: "status",
            title: "Order Status",
            render: (record) => {
                return (
                    <>
                        {" "}
                        <div className="d-flex" style={{ gap: '6px' }}>
                            {record.status != 'delivered' && record.status != 'failed' && record.status != 'canceled' ? (<>

                                <button
                                    className="badge badge bg-light-danger"
                                    style={{ border: '0' }}
                                    onClick={() => Edit(record)}
                                >
                                    <i class="ft-edit"></i>
                                </button></>
                            ) : (<><div style={{ width: '32px' }}></div></>)}
                            {record.status === 'delivered' ? (
                                <span className="badge bg-light-success">{record.status}</span>
                            ) : record.status === 'failed' || record.status === 'canceled' ? (
                                <span className="badge bg-light-danger">{record.status}</span>
                            ) : record.status === 'shipped' ? (
                                <span className="badge bg-light-warning">{record.status}</span>
                            ) : (
                                <span className="badge bg-light-info">{record.status}</span>
                            )}

                        </div>
                    </>
                );
            },
        },
        {
            key: "action",
            title: "",
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                            <Link to={`/order/${record.id}`}>
                                <button className="badge badge-pill bg-light-secondary mb-1 mr-2"
                                    style={{ border: '2px solid #dadada' }}>
                                    <i class="ft-eye"></i>
                                </button>
                            </Link>
                        </div>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        loadTableData();
    }, []);
    const loadTableData = () => {
        setOrderStatusMVisible(false)
        setLoading(true);
        OrderAPI.getAllOrders().then((response) => {
            setTableData(response.data.data)
            setLoading(false);

        }).catch((error) => { console.error(error) });
    }

    const Edit = (data) => {
        let originalList = commonFunctions.getOrderStatuses();
        let changeArray = [];
        let statusId;
        setSelectedStatus(data.status);

        for (var i = 0; i < originalList.length; i++) {
            if (data.status == originalList[i].id) {
                statusId = i;
            }
            if (statusId == 0 && (i == 0 || i == 1 || i == 4)) {
                changeArray.push({ id: originalList[i].id, name: originalList[i].name });
            } else if (statusId == 1 && (i == 1 || i == 2 || i == 4)) {
                changeArray.push({ id: originalList[i].id, name: originalList[i].name });
            } else if (statusId == 2 && (i == 2 || i == 3)) {
                changeArray.push({ id: originalList[i].id, name: originalList[i].name });
            }
        }
        setStatusList(changeArray);
        setOrderNewStatus(data.status);
        setOrderId(data.id)
        setOrderStatusMVisible(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedStatus == orderNewStatus) {
            Swal.fire("Warning", "Please change the status", "warning");
        } else {
            OrderAPI.saveNewOrderStatus(parseInt(orderId), orderNewStatus).then((response) => {
                if (response.data.status_code === 0) {
                    Swal.fire("Success", "" + response.data.message, "success").then(function () {
                        loadTableData();
                    });
                } else {
                    Swal.fire("Warning", " " + response.data.message, "warning");
                }
            }).catch((error) => {
                Swal.fire("Warning", "" + error.response.data.message, "warning");
                console.error(error)
            });
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h3> Orders </h3>
                </div>
            </div>
            <section id="simple-table">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="row">

                                        <div className="col-md-6">
                                            <Input.Search
                                                placeholder="Search here..."
                                                style={{ marginBottom: 5 }}
                                                value={searchedText}
                                                onSearch={(value) => {
                                                    setSearchedText(value);
                                                }}
                                                onChange={(e) => {
                                                    setSearchedText(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <div className="table">
                                            <Table
                                                loading={loading}
                                                dataSource={tableData}
                                                columns={columns}
                                                pagination={{
                                                    pageSize: 10,
                                                    showSizeChanger: true,
                                                    position: ["bottomCenter"],
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                title={"Order Status"}
                visible={orderStatusMVisible}
                okText="Save"
                onCancel={() => setOrderStatusMVisible(false)}
                onOk={(e) => {
                    handleSubmit(e);
                }}
            >
                <DropDown
                    label={"Select order status"}
                    placeholder="select branch"
                    name={'orderNewStatus'}
                    onchange={(e) => setOrderNewStatus(e.target.value)}
                    list={statusList}
                    value={orderNewStatus}
                />
            </Modal>
        </>
    );
}
export default Order;
