import API from "./AxiosHeaders";
import {endPoints} from "./APIEndPoints";

const saveNewAdjust = async (data) => {
    return await API.commonInstance.post(endPoints.saveNewAdjustment, data);
}

const getBranchStockItems = async (branchId) => {
    return await API.commonInstance.get(endPoints.getBranchStockList+`/`+branchId);
}

const getAdjustmentList = async () => {
    return await API.commonInstance.get(endPoints.getAllAdjustment);
}


const StockAPI = {
    saveNewAdjust,
    getBranchStockItems,
    getAdjustmentList
};

export default StockAPI;