import React, {useState, useEffect, useRef} from "react";

import Swal from "sweetalert2";
import {DatePicker, Radio, Space} from "antd";
import moment from "moment";
import TextInput from "../components/form/TextInput";
import {IMAGE_BASE_URL, ORG_ID} from "../AppConstant";
import Label from "../components/form/Label";
import CategoryAPI from "../services/CategoryAPIServices";
import ProductAPI from "../services/ProductAPIServices";
import {useParams} from "react-router-dom";
import $ from 'jquery'

function EditProduct() {
    const { id } = useParams();
    const inputRef = useRef();
    const { RangePicker } = DatePicker;
    const [title, setTitle] = useState("");
    const [discount, setDiscount] = useState('0');
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("1");
    const [category_id, setCategoryId] = useState("");
    const [discount_start_date, setDiscountStart] = useState("");
    const [discount_end_date, setDiscountEnd] = useState("");
    const [image, setImage] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [product_id, setProductId] = useState(id);
    const [currentImage, setCurrentImage] = useState("");
    const [price, setPrice] = useState("0");

    useEffect(() => {
        CategoryAPI.getActiveCategoryList().then((response)=>{
            setCategoryList(response.data.data);
        });

        ProductAPI.getSingleProductData(id).then((response) => {

            setTitle(response.data.data.title);
            setDescription(response.data.data.description);
            setStatus(response.data.data.status.toString());
            setDiscount(response.data.data.discount);
            setCategoryId(response.data.data.category_id.toString());
            setDiscountStart(response.data.data.discount_start_date === "0000-00-00" || response.data.data.discount_start_date === null ? "":response.data.data.discount_start_date);
            setDiscountEnd(response.data.data.discount_end_date === "0000-00-00" || response.data.data.discount_end_date == null ? "" : response.data.data.discount_end_date);
            setCurrentImage(response.data.data.image);
            if(image){
                inputRef.current.value = "";
            }
            setImage("");
            setPrice(response.data.data.price);

        }).catch((error) => { console.error(error) });

    }, []);


    const handleUpdateFrom = (e) => {
        e.preventDefault();
        let id = parseInt(product_id);
        const data = {id,title,description,status,discount,category_id,discount_start_date,discount_end_date,image,price}

        if(parseFloat(discount).toFixed(2) < 0){
            Swal.fire("Warning", "Minus value is not allowed for discount field", "warning");
        }else if(parseFloat(discount).toFixed(2) > 0 && (discount_start_date === "" || discount_end_date === "")){
            Swal.fire("Warning", "Please add the discount valid date range", "warning");
        }else if(parseFloat(price).toFixed(2) <= 0 || price === "" || price == null){
            Swal.fire("Warning", "Minus / zero / null value is not allowed for price field", "warning");
        }else {
            $("#submitButton").prop("disabled", true);
            ProductAPI.editProductDetails(data).then((response) => {
                if (response.data.status_code === 0) {
                    Swal.fire("Success", "" + response.data.message, "success").then(function () {
                        window.location.replace("/products");
                    });
                } else {
                    $("#submitButton").prop("disabled", false);
                    Swal.fire("Warning", " " + response.data.message, "warning");
                }
            }).catch((error) => {
                $("#submitButton").prop("disabled", false);
                Swal.fire("Warning", "" + error.response.data.message, "warning");
                console.error(error)
            });
        }
    };

    const onChangeDate = (dates, dateStrings) => {
        if (dates) {
            setDiscountStart(dateStrings[1]);
            setDiscountEnd(dateStrings[0]);
        } else {
            setDiscountStart("");
            setDiscountEnd("");
        }
    };

    const encodeImageFileAsURL = async (element) => {
        let file = element.target.files[0];
        let reader = new FileReader();
        reader.onloadend = function() {
            setImage(reader.result)
        }
        reader.readAsDataURL(file);
    }


    const onChangeStatus = (e) => {
        setStatus(e.target.value);
    };

    return (
        <>
            <div className="container-xxl">
            <div className="row">
                <div className="col-8">
                    <h3> Edit - {title} </h3>
                </div>
                <div className="col-4">
                    <button
                        type="button"
                        className="btn btn-primary mb-3 d-block  float-right"
                        id={"submitButton"}
                        onClick={(e) => handleUpdateFrom(e)}
                    >
                        Save Changes
                    </button>
                </div>
            </div>
            <div className="row g-3 mb-3">
                <div className="col-xl-8 col-lg-8">
                    <div className="sticky-lg-top">
                        <div className="card mb-3">
                            <div className="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                                <h6 className="m-0 fw-bold">Basic Information</h6>
                            </div>
                            <div className="card-body">
                                <div className="row g-3 align-items-center">
                                    <div className="col-md-12">
                                        <TextInput
                                            label={"Name"}
                                            placeholder="Product Name"
                                            name="product_name"
                                            onchange={(e) => setTitle(e.target.value)}
                                            value={title}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <Label label={"Product Description"} required={true}/>
                                        <textarea className="form-control" id="description" name="description"  value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Enter Product Description Here"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                                <h6 className="m-0 fw-bold">Pricing Info</h6>
                            </div>
                            <div className="card-body">
                                <div className="row g-3 align-items-center">
                                    <div className="col-md-6">
                                        <TextInput
                                            label={"Price"}
                                            placeholder="Price"
                                            name="price"
                                            type={'number'}
                                            min={1}
                                            onchange={(e) => setPrice(e.target.value)}
                                            value={price}
                                        />
                                    </div>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <TextInput
                                            label={"Discount"}
                                            placeholder="Discount"
                                            name="discount"
                                            type={'number'}
                                            min={1}
                                            onchange={(e) => setDiscount(e.target.value)}
                                            value={discount}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Label label={"Discount Date Range"}/>
                                            <RangePicker
                                                ranges={{
                                                    Today: [moment(), moment()],
                                                    "This Month": [
                                                        moment().startOf("month"),
                                                        moment().endOf("month"),
                                                    ],
                                                }}
                                                onChange={onChangeDate}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                    <div className="card mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                            <h6 className="m-0 fw-bold">Visibility Status</h6>
                        </div>
                        <div className="card-body">
                            <div className="row g-3 align-items-center">
                            <div className="col-md-12">
                                <Radio.Group onChange={onChangeStatus} value={status}>
                                    <Space direction="vertical">
                                        <Radio value={"1"}> <Label label={"Published"} /> </Radio>
                                        <Radio value={"0"}> <Label label={"Hidden"}/> </Radio>
                                    </Space>
                                </Radio.Group>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                            <h6 className="m-0 fw-bold">Other Details</h6>
                        </div>
                        <div className="card-body">
                            <div className="row g-3 align-items-center">
                            <div className="col-md-12 mb-4">
                                <Label label={"Select Category"} required={true}/>
                                <select className="form-control" id="category" name="category" value={category_id}  onChange={(e) => setCategoryId(e.target.value)}>
                                    <option value="" disabled selected>Select category </option>
                                    {categoryList.map((item, index) => (<>
                                        <option value={item.key} style={{fontWeight:500}} key={item.key}> {item.name}</option>

                                        {item.children.map((subItem, i) => (
                                            <option value={subItem.key} key={subItem.key}>&emsp; {subItem.name}</option>
                                        ))}

                                    </>))}
                                </select>
                            </div>

                            <div className="col-md-12 mb-2" >
                                <Label label={"Replace New Image"}/>
                                <input type="file" name="image" id="image" ref={inputRef} accept="image/*" className="form-control" onChange={encodeImageFileAsURL} required/>
                            </div>
                            <div className="col-md-12" id="image-preview-area">
                                <h6>Current Image</h6>
                                <img alt={'product-image'} className="img-fluid" width={200} src={`${IMAGE_BASE_URL}${currentImage}`}/>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}
export default EditProduct;
