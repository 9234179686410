import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Input } from "antd";
import MasterFileAPI from "../../services/MasterFileAPIServices";
import CommonFunctions from "../../utils/CommonFunctions";
import ProductAPI from "../../services/ProductAPIServices";

function MasterProductOrg() {

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [orgProductMasterIds, setOrgProductMasterIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [org_id, setOrgId] = useState(CommonFunctions.getOrgId());
  const [buttonLoading, setButtonLoading] = useState(false);

  const columns = [
    {
      key: "key",
      title: "ID",
      dataIndex: "key",
    },
    {
      key: "title",
      title: "Name",
      dataIndex: "title",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          record.title.toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (value, record) => {
        return (
          <>
            {record.type == 'category' ? (<>
              <b><span>{value} </span> </b> <i><small> Category</small></i>
            </>
            ) : (<>
              <span>{value}</span>
            </>)}

          </>
        );
      },
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
      render: (value, record) => {
        return (
          <>
            {record.type == 'category' ? (<>

            </>
            ) : (<>
              <span>{value}</span>
            </>)}

          </>
        );
      },
    },
    {
      key: "price",
      title: "Price",
      dataIndex: "price",
      render: (value, record) => {
        return (
          <>
            {record.type == 'category' ? (<>

            </>
            ) : (<>
              <span>{value}</span>
            </>)}

          </>
        );
      },
    },

  ];

  useEffect(() => {
    loadTableData();
  }, []);

  const loadTableData = () => {
    const org_data = { org_id: parseInt(org_id) };
    ProductAPI.getProductListWithMasterIds(org_data).then((response) => {
      const masterIdFlattenedArray = response.data.data.map(obj => obj['master_id']).flat();
      console.log('masterIdFlattenedArray',masterIdFlattenedArray);
      setOrgProductMasterIds(masterIdFlattenedArray);
      setSelectedRowKeys(masterIdFlattenedArray);
    }).catch((error) => { console.error(error) });

    setLoading(true);
    MasterFileAPI.getMasterProductForOrg().then((response) => {
      setTableData(response.data.data)
      setLoading(false);

    }).catch((error) => { console.error(error) });
  }

  const handleSubmit = (e) => {
    const uniqueCategories = selectedRows.slice(orgProductMasterIds.length);
    console.log('element', uniqueCategories);
    const nameArray = uniqueCategories.map(obj => obj['title']).flat();
    const jsonString = JSON.stringify(nameArray, null, 2);

    if (uniqueCategories.length == 0) {
      Swal.fire("Warning", "Please select the product", "warning");
    } else {
      Swal.fire({
        title: "Are you sure the below producs will copied?",
        text: jsonString,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Copy it!"
      }).then((result) => {
        if (result.isConfirmed) {
          setButtonLoading(true);
          const copy_data = { products: uniqueCategories }
          ProductAPI.saveProductFromMaster(copy_data).then((response) => {
            if (response.data.status_code === 0) {
              Swal.fire("Success", "" + response.data.message, "success").then(function () {
                loadTableData();
              });
            } else {
              Swal.fire("Warning", " " + response.data.message, "warning");
            }
            setButtonLoading(false);
          }).catch((error) => {
            setButtonLoading(false);
            Swal.fire("Warning", "" + error.response.data.message, "warning");
            console.error(error)
          });
        }
      });

    }
  };


  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    console.log('selectedRows', selectedRows);

    // setSelectedRows(prevItems => [...prevItems, lastElement]);
    setSelectedRows(selectedRows);
  };

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      if (orgProductMasterIds.includes(record.key)) {
        return { disabled: true };
      }else if(record.children){
        return { disabled: true };
      }
      return {}; 
    },
  };
 

  // const [expandedKeys, setExpandedKeys] = useState([]);

  // // Function to expand all rows and their children
  // const expandAllRows = () => {
  //   const keys = [];
  //   tableData.forEach((parent) => {
  //     keys.push(parent.key);
  //     parent.children.forEach((child) => {
  //       keys.push(child.key);
  //     });
  //   });
  //   setExpandedKeys(keys);
  // };

  // // Call expandAllRows function to expand all rows and their children
  // expandAllRows();

  // const handleExpand = (keys) => {
  //   setExpandedKeys(keys);
  // };

  // const expandable = {
  //   expandedRowKeys: expandedKeys,
  //   onExpand: handleExpand,
  // };


  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3> Get Products </h3>
        </div>

      </div>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <Input.Search
                        placeholder="Search here..."
                        style={{ marginBottom: 5 }}
                        value={searchedText}
                        onSearch={(value) => {
                          setSearchedText(value);
                        }}
                        onChange={(e) => {
                          setSearchedText(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 text-right mb-2">
                    <button
                      className="btn btn-primary mr-2 mb-3 float-right"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      disabled={buttonLoading}
                    >
                      {buttonLoading ? (
                        <>
                          <i className="ft-refresh-ccw"> </i>
                          <span>Submitting...</span>
                        </>
                      ):(<><span> Save to Product</span>{" "}</>)}
              
                    </button>
                  </div>
                  </div>
                  <div className="table-responsive">

                    <div className="table">
                      <Table
                        loading={loading}
                        columns={columns}
                        rowSelection={{
                          ...rowSelection,
                          // getCheckboxProps: (record) => ({
                          
                          // }),
                          // onSelect: (record, selected) => handleCheckboxChange(record.key, selected),
                        }}
                        
                        dataSource={tableData}
                        pagination={{
                          pageSize: 10,
                          position: ["bottomCenter"],
                        }}
                        defaultExpandAllRows={true} 
                        rowClassName={(record) => (record.children ? 'parent-row' : 'child-row')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
export default MasterProductOrg;
