import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import AuthAPI from "../services/AuthAPIServices";

function ForgotPassword() {

    const [email, setEmail] = useState("");

    function sendingMail(event) {

        if(email == "" || email == null){
            Swal.fire("warning", "Please fill the email.", "warning");
        }else{
            AuthAPI.forgotPasswordApi(email).then((response) => {
                if (response.data.status_code == 0) {
                    Swal.fire("Success", "" + response.data.message, "success");
                } else {
                    Swal.fire("Warning", "" + response.data.message, "warning");
                }
            }).catch((error) => {
                Swal.fire("Warning", ""+error.response.data.message, "warning");
            });
        }
    }

    return (
        <div className="content-wrapper" style={{background:"#dadee3"}}>
                        <section id="forgot-password" className="auth-height">
                            <div className="row full-height-vh m-0 d-flex align-items-center justify-content-center">
                                <div className="col-md-7 col-12">
                                    <div className="card overflow-hidden">
                                        <div className="card-content">
                                            <div className="card-body auth-img">
                                                <div className="row m-0">
                                                    <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                                                        <img
                                                            src="../../../app-assets/img/gallery/forgot.png"
                                                            alt=""
                                                            className="img-fluid"
                                                            width="260"
                                                            height="230"
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 px-4 py-3">
                                                        <h4 className="mb-2 card-title">Recover Password</h4>
                                                        <p className="card-text mb-3">
                                                            Please enter your email address and we'll send you
                                                            instructions on how to reset your password.
                                                        </p>
                                                        <input
                                                            type="email"
                                                            className="form-control mb-3"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            placeholder="Email"
                                                        />
                                                        <div className="d-flex flex-sm-row flex-column justify-content-between">
                                                            <Link to={`/`}  className="btn bg-light-primary mb-2 mb-sm-0"> Back To Login</Link>
                                                            <button type="button" className="btn btn-primary ml-sm-1"  onClick={sendingMail}> Recover </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
        </div>
    );
}
export default ForgotPassword;
