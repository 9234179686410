import React from "react";

function DropDown({value = '', onchange = () => { }, name = '', label = '', disabled = false,required = false, list=[], initial = true,stylePointer=''}) {
    return( <>
        <label className="form-label"> {label} {required ? <span style={{ color: "red" }}>*</span>:null} </label>
        <select
            style={{pointerEvents:`${stylePointer}`}}
            name={name}
            className="select2 form-control"
            value={value}
            onChange={onchange}
            required={required}
            disabled={disabled}
           
        >
            {initial ?  <option value={""} key={0} disabled={true}> {"Select"} </option> : ''}
            {list.map((item, i) => {
                return (
                    <>
                        <option value={item.id} key={i}> {item.name} </option>
                    </>
                );
            })}
        </select>
    </>);
}
export default DropDown;

