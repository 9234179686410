import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Modal, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {Link, useParams} from "react-router-dom";
import OrderAPI from "../services/OrderAPIServices";
import CustomerAPI from "../services/CustomerAPIServices";
import moment from "moment";

function ViewCustomer() {
    const { id } = useParams();
    const [tableData, setTableData] = useState([]);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [registeredDate, setRegisteredDate] = useState("");
    const [status, setStatus] = useState("");
    const [userId, setUserId] = useState("0");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        loadContent();
    }, []);

    const columns = [
        {
            key: "order_ref",
            title: "Reference",
            dataIndex: "order_ref",
            filterMode: "tree",
            filterSearch: true,
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                             }) => {
                return (
                    <>
                        <Input
                            autoFocus
                            placeholder="Type Reference"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropDown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        ></Input>
                        <Button
                            onClick={() => {
                                confirm();
                            }}
                            type="primary"
                        >
                            Search
                        </Button>
                    </>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.order_ref
                    .toLowerCase()
                    .includes(value.toLowerCase());
            },
        },
        {
            key: "pay_method",
            title: "Payment Method",
            dataIndex: "pay_method"
        },
        {
            key: "created_at",
            title: "Ordered Date",
            render: (record) => {
                return (
                    <>
                        <span>{moment(record.created_at).format('DD/MM/YYYY')}</span>
                    </>
                );
            },
        },
        {
            key: "net_amount",
            title: "Price",
            dataIndex: "net_amount"
        },
        {
            key: "status",
            title: "Order Status",
            render: (record) => {
                return (
                    <>
                        {" "}
                        <div className="flex">
                            {record.status === 'delivered' ? (
                                <span className="badge bg-light-success">{record.status}</span>
                            ) : record.status === 'failed'?(
                                <span className="badge bg-light-danger">{record.status}</span>
                            ):record.status === 'shipped' ? (
                                <span className="badge bg-light-warning">{record.status}</span>
                            ):(
                                <span className="badge bg-light-info">{record.status}</span>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            key: "action",
            title: "",
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                            <Link to={`/order/${record.id}`}>
                                <button className="badge badge-pill bg-light-secondary mb-1 mr-2"
                                        style={{border: '2px solid #dadada'}}>
                                    <i class="ft-eye"></i>
                                </button>
                            </Link>
                        </div>
                    </>
                );
            },
        },
    ];

    const loadContent =()=>{
        CustomerAPI.getCustomerDetails(id).then((response) => {
            setName(response.data.data.customerData.first_name+' '+response.data.data.customerData.last_name);
            setEmail(response.data.data.customerData.email);
            setAddress(response.data.data.customerData.street_address);
            setCity(response.data.data.customerData.city);
            setState(response.data.data.customerData.state);
            setPostalCode(response.data.data.customerData.postal_code);
            setPhoneNo(response.data.data.customerData.phone_number);
            setRegisteredDate(moment(response.data.data.customerData.created_at).format('DD/MM/YYYY H:mm:ss'));

            setTableData(response.data.data.orders);

        }).catch((error) => { console.error(error) });
    }
    return (
        <>
            <div className="row">
                <div className="col-8">
                    <h3> {name} </h3>
                </div>
            </div>
            <div className="container-xxl">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header"><h6 className="card-title">Location Info</h6></div>
                                <div className="card-body">
                                    <div className="row g-3 align-items-center">
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Address:</label>
                                            <span className="font-small-2"><strong>{address}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">City:</label>
                                            <span className="font-small-2"><strong>{city}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">State:</label>
                                            <span className="font-small-2"><strong>{state}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Country:</label>
                                            <span className="font-small-2"><strong>{country}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Post Code:</label>
                                            <span className="font-small-2"><strong>{postalCode}</strong></span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header"><h6 className="card-title">Contact Info</h6></div>
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Email:</label>
                                            <span className="font-small-2"><strong>{email}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Phone No:</label>
                                            <span className="font-small-2"><strong>{phoneNo}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Status:</label>
                                            <span><strong></strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Registered Date:</label>
                                            <span className="font-small-2"><strong>{registeredDate}</strong></span>
                                        </div>
                                        <div className="col-12"> <br/></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header"><h6 className="card-title">Customer Orders</h6></div>
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <div className="table">
                                                    <Table
                                                        loading={loading}
                                                        dataSource={tableData}
                                                        columns={columns}
                                                        pagination={{
                                                            pageSize: 10,
                                                            showSizeChanger: true,
                                                            position: ["bottomCenter"],
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ViewCustomer;
