import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import {Modal, Select, Timeline} from "antd";
import { Link, useParams } from "react-router-dom";
import DropDown from "../components/form/DropDown";
import OrderAPI from "../services/OrderAPIServices";
import moment from "moment/moment";
import commonFunctions from "../utils/CommonFunctions";

function ViewOrder() {
    const { id } = useParams();
    const [orderList, setOrderList] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [orderStatusList, setOrderStatusList] = useState([{"children":"started"}]);
    const [statusList, setStatusList] = useState([]);
    const [ref, setRef] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [createdDate, setCreatedDate] = useState("");
    const [status, setStatus] = useState("");
    const [remark, setRemark] = useState("");
    const [shippingName, setShippingName] = useState("");
    const [orderNewStatus, setOrderNewStatus] = useState("0");
    const [paymentNewStatus, setPaymentNewStatus] = useState("0");
    const [orderId, setOrderId] = useState(id);
    const [payMVisible, setPayMVisible] = useState(false);
    const [orderStatusMVisible, setOrderStatusMVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(id);
    const [paidDate, setPaidDate] = useState("");
    const [payMethod, setPayMethod] = useState("");
    const [transcation, setTransaction] = useState("");
    const [paidAmount, setPaidAmount] = useState("");
    const [currency_code, setCurrencyCode] = useState("");
    const [sub_total, setSubTotal] = useState("");
    const [delivery_fee, setDeliveryFee] = useState("");
    const [final_total, setFinalTotal] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");

    let statusArray = [];
    let orderArray = [];

    useEffect(() => {

        OrderAPI.getAllOrders().then((response)=>{

            for (var i = 0; i < response.data.data.length; i++) {
                orderArray.push({"value":response.data.data[i].id,"label":response.data.data[i].order_ref+' - '+response.data.data[i].status});

                if(response.data.data[i].id == selectedId){
                    setSelectedId({"value":response.data.data[i].id,"label":response.data.data[i].order_ref+' - '+response.data.data[i].status})
                }

            }
            setOrderList(orderArray);
        });

        loadContent();

    }, []);

    const truncate = (input) =>
        input?.length > 20 ? `${input.substring(0, 17)}...` : input;

    const loadContent =()=>{

        setOrderStatusMVisible(false);
        OrderAPI.getSingleOrderData(id).then((response) => {

            setRef(response.data.data.main_details.order_ref);
            setRemark(response.data.data.main_details.remarks);
            setCreatedDate(moment(response.data.data.main_details.created_at).format('DD/MM/YYYY H:mm:ss'));
            setCurrencyCode(response.data.data.main_details.currency_code);
            setSubTotal(response.data.data.main_details.sub_total);
            setDeliveryFee(response.data.data.main_details.delivery_fee);
            setFinalTotal(response.data.data.main_details.net_amount);
            setStatus(response.data.data.main_details.status);
            setOrderNewStatus(response.data.data.main_details.status);
            setEmail(truncate(response.data.data.main_details.customer.email))

            setAddress(response.data.data.shipping_details.address);
            setState(response.data.data.shipping_details.state);
            setCity(response.data.data.shipping_details.city);
            setPostalCode(response.data.data.shipping_details.post_code);
            setPhoneNo(response.data.data.shipping_details.phone_no);
            setShippingName(response.data.data.shipping_details.full_name);

            setPayMethod(response.data.data.main_details.pay_method);
            setTransaction(response.data.data.paymentDetails.transaction);
            setPaidAmount(response.data.data.main_details.currency_code+' '+response.data.data.main_details.net_amount);
            setPaidDate(response.data.data.main_details.pay_method == 'card'? moment(response.data.data.paymentDetails.created_at).format('DD/MM/YYYY H:mm:ss') : response.data.data.paymentDetails.created_at)
            setPaymentStatus(response.data.data.paymentDetails.status)

            setTableData(response.data.data.items);

            if(statusArray.length == 0){
                for (var i = 0; i < response.data.data.status.length; i++) {
                    statusArray.push({"children":response.data.data.status[i].status+' ' + moment(response.data.data.status[i].change_date).format('DD/MM/YYYY H:mm:ss')});
                }
            }

            setOrderStatusList(statusArray)

        }).catch((error) => { console.error(error) });
    }

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const updatePaidStatus = (data) => {
        setPayMVisible(true);
    };

    const updateOrderStatus = () => {
        let originalList = commonFunctions.getOrderStatuses();
        let changeArray = [];
        let statusId;

        for (var i = 0; i < originalList.length; i++) {
            if(status == originalList[i].id){
                statusId = i;
            }
            if(statusId == 0 && (i==0 || i==1 || i == 4)){
                changeArray.push({id:originalList[i].id,name:originalList[i].name});
            }else if(statusId == 1 && (i==1 || i==2 || i == 4)){
                changeArray.push({id:originalList[i].id,name:originalList[i].name});
            }else if(statusId == 2 && (i==2 || i==3 )){
                changeArray.push({id:originalList[i].id,name:originalList[i].name});
            }
        }
        setStatusList(changeArray);

        setOrderStatusMVisible(true);
    };

    const onChange = (value) => {
        setSelectedId(value);
        window.location.replace("/order/"+value.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if(status == orderNewStatus){
            Swal.fire("Warning", "Please change the status", "warning");
        }else{
            OrderAPI.saveNewOrderStatus(parseInt(orderId),orderNewStatus).then((response) => {
                if (response.data.status_code === 0) {
                    Swal.fire("Success", ""+response.data.message, "success").then(function() {
                        loadContent();
                    });
                }else{
                    Swal.fire("Warning", " " + response.data.message, "warning");
                }
            }).catch((error) => {
                Swal.fire("Warning", ""+error.response.data.message, "warning");
                console.error(error)
            });
        }

    };

    return (
        <>
            <div className="row">
                <div className="col-8">
                    <h3> Order Details: {ref} </h3>
                </div>
                <div className="col-md-4">
                    <Select
                        labelInValue
                        style={{width:'100%'}}
                        showSearch
                        placeholder="Select a product"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        options={orderList}
                        value={selectedId}
                    />
                    {/*<DropDown*/}
                    {/*    placeholder="select branch"*/}
                    {/*    name={'selected_id'}*/}
                    {/*    onchange={(e) => changeOrderId(e.target.value)}*/}
                    {/*    value={selectedId}*/}
                    {/*    list={orderList}*/}
                    {/*/>*/}
                </div>
            </div>
            <div className="container-xxl">
                <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                        <div className="card bg-success bg-lighten-3">
                            <div className="card-content">
                                <div className="card-body py-0">
                                    <div className="media">
                                        <div className="media-body success text-left">
                                            <h6 className="success mb-0">Order Created at</h6>
                                            <span>{createdDate}</span>
                                        </div>
                                        <div className="media-right success text-right">
                                            <i className="ft-shopping-cart font-large-1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                        <div className="card bg-warning bg-lighten-3">
                            <div className="card-content">
                                <div className="card-body py-0">
                                    <div className="media">
                                        <div className="media-body warning text-left">
                                            <h6 className="warning mb-0">Name</h6>
                                            <span>{shippingName}</span>
                                        </div>
                                        <div className="media-right warning text-right">
                                            <i className="ft-user font-large-1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                        <div className="card bg-info bg-lighten-3">
                            <div className="card-content">
                                <div className="card-body py-0">
                                    <div className="media">
                                        <div className="media-body info text-left">
                                            <h6 className="info mb-0">Email</h6>
                                            <span>{email}</span>
                                        </div>
                                        <div className="media-right info text-right">
                                            <i className="ft-mail font-large-1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                        <div className="card bg-danger bg-lighten-3">
                            <div className="card-content">
                                <div className="card-body py-0">
                                    <div className="media">
                                        <div className="media-body danger text-left">
                                            <h6 className="danger mb-0">Contact No</h6>
                                            <span>{phoneNo}</span>
                                        </div>
                                        <div className="media-right danger text-right">
                                            <i className="ft-phone font-large-1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header"><h6 className="card-title">Shipping Details</h6></div>
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="row g-3 align-items-center">
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Address:</label>
                                            <span><strong>{address}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">City:</label>
                                            <span><strong>{city}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">State:</label>
                                            <span><strong>{state}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Country:</label>
                                            <span><strong>{country}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Post Code:</label>
                                            <span><strong>{postalCode}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h6 className="card-title">Payment Details</h6>
                                {/*<a className="text-muted" data-bs-toggle="modal" onClick={() => updatePaidStatus()} ><i className="ft-edit"></i> status</a>*/}
                            </div>
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Pay Method:</label>
                                            <span className="font-small-3"><strong>{payMethod}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Paid Amount:</label>
                                            <span className="font-small-3"><strong>{paidAmount}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Transaction:</label>
                                            <span className="font-small-3"><strong>{transcation}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Paid Status:</label>
                                            <span className="font-small-3"><strong>{paymentStatus}</strong></span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Paid Date:</label>
                                            <span className="font-small-3"><strong>{paidDate}</strong></span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header"><h6 className="card-title">Order Summary</h6></div>
                            <div className="card-content">
                                <div className="card-body">
                                    <div id="invoice-items-details">
                                        <div className="row">
                                            <div className="table-responsive col-12">
                                                <table className="table mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th>Product</th>
                                                            <th className="text-right">Quantity</th>
                                                            <th className="text-right">Unit Price</th>
                                                            <th className="text-right">Discount</th>
                                                            <th className="text-right">Amount ({currency_code})</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tableData.map((_, index) => (<>
                                                            <tr >
                                                            <td><p>{tableData[index].product.title}</p></td>
                                                            <td className="text-right">{tableData[index].quantity}</td>
                                                            <td className="text-right">{tableData[index].unit_price}</td>
                                                            <td className="text-right">{tableData[index].discount}</td>
                                                            <td className="text-right">{tableData[index].total_price}</td>
                                                        </tr>
                                                        </>))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row mt-3 mt-md-0">
                                            <div className="col-md-6 col-12 text-left">
                                                <p className="text-bold-700 mb-1 ml-1">Remarks:</p>
                                                <p className="ml-1">{remark}</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <p className="text-bold-700 mb-2 ml-4">Total due</p>

                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>Sub Total</td>
                                                                <td className="text-right">{sub_total}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Delivery fee</td>
                                                                <td className="text-right">{delivery_fee}</td>
                                                            </tr>
                                                            <tr className="text-bold-500">
                                                                <td>Total</td>
                                                                <td className="text-right"> {final_total}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h6 className="card-title">Order Status History</h6>
                                {status != 'delivered' && status != 'failed' && status != 'canceled' ? (<>
                                    <a className="text-muted" data-bs-toggle="modal" onClick={() => updateOrderStatus()} ><i className="ft-edit"></i> status</a></>) :
                                    (<></>)}

                            </div>
                            <div className="card-content">
                                <div className="card-body">
                                    <Timeline
                                        mode="alternate"
                                        reverse={true}
                                        items={orderStatusList}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title={"Payment Status"}
                visible={payMVisible}
                okText="Save"
                onCancel={() => setPayMVisible(false)}
                onOk={() => setPayMVisible(false)}
            >
                <DropDown
                    label={"Select payment status"}
                    placeholder="select status"
                    name={'new_payment_status'}
                    list={[{ 'id': 1, 'name': '1233' }, { 'id': 2, 'name': '57657' }]}
                    onchange={(e) => setPaymentNewStatus(e.target.value)}
                    value={paymentNewStatus}
                />

            </Modal>
            <Modal
                title={"Order Status"}
                visible={orderStatusMVisible}
                okText="Save"
                onCancel={() => setOrderStatusMVisible(false)}
                onOk={(e) =>  {
                    handleSubmit(e);
                }}
            >
                <DropDown
                    label={"Select order status"}
                    placeholder="select branch"
                    name={'orderNewStatus'}
                    onchange={(e) => setOrderNewStatus(e.target.value)}
                    list={statusList}
                    value={orderNewStatus}
                />  
            </Modal>
        </>
    );
}
export default ViewOrder;
