import { useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import AuthAPI from "../services/AuthAPIServices";
import commonFunctions from "../utils/CommonFunctions";

function Login() {
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(email === "" || email == null || password === "" || password == null){
            Swal.fire("warning", "Please fill the required data.", "warning");
        }else{

            AuthAPI.signInApi(email,password).then((response) => {
                if(response.data.status_code === 0){
                    commonFunctions.setToken(response.data.data.token,response.data.data.first_name,response.data.data.id,response.data.data.org_id);
                    if(response.data.data.user_type == '1'){
                        navigate("/master-categories");
                    }else{
                        navigate("/locations");
                    }
                    
                    window.location.reload();
                }else{
                    Swal.fire("warning", response.data.message, "warning");
                }

            }).catch((error) => { console.error(error) });
        }
    };

    return (
        <>
            <div className="content-wrapper" style={{background:"#dadee3"}} >
                <section id="login" className="auth-height" >
                    <div className="row full-height-vh m-0">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="card overflow-hidden">
                                <div className="card-content">
                                    <div className="card-body auth-img"  >
                                        <div className="row m-0">
                                            <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                                                <img
                                                    src="app-assets/img/Foxatab_Logo_2.svg"
                                                    alt=""
                                                    className="img-fluid"
                                                    width="300"
                                                    height="230"
                                                />
                                            </div>
                                            <div className="col-lg-6 col-12 px-4 py-3">
                                                <h4 className="mb-2 card-title">Login</h4>
                                                <p style={{color:"#79829A"}}>Welcome back, please login to your account.</p>
                                                <form
                                                    id="login-form"
                                                    onSubmit={(e) => {
                                                        handleSubmit(e);
                                                    }}
                                                >
                                                    <input
                                                        type="email"
                                                        className="form-control mb-3"
                                                        placeholder="Email"
                                                        name="email"
                                                        id="email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                    <input
                                                        type="password"
                                                        className="form-control mb-2"
                                                        placeholder="Password"
                                                        name="password"
                                                        id="password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        required
                                                    />
                                                    <div className="d-sm-flex justify-content-between mb-3 font-small-2">
                                                        <div className="remember-me mb-2 mb-sm-0">
                                                            <div className="checkbox auth-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id="auth-ligin"
                                                                    checked={rememberMe}
                                                                    onChange={handleSetRememberMe}
                                                                    onClick={handleSetRememberMe}
                                                                />
                                                                <label for="auth-ligin">
                                                                    <span style={{color:"#79829A"}}>Remember Me</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <Link to={`/forgot-password`}>Forgot Password?</Link>
                                                    </div>
                                                    <div className="d-flex justify-content-between flex-sm-row flex-column">
                                                        <button type="submit" className="btn btn-primary" >
                                                            Login
                                                        </button>
                                                    </div>
                                                </form>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Login;
