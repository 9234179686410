import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import AuthAPI from "../services/AuthAPIServices";
import commonFunctions from "../utils/CommonFunctions";
import TextInput from "../components/form/TextInput";

function ChangePassword() {
    const [old_password, setOldPassword] = useState("");
    const [new_password, setNewPassword] = useState("");
    const [confirmed_new_password, setConfirmedNewPassword] = useState("");
    const user_id = parseInt(commonFunctions.getUserId());

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            new_password != confirmed_new_password ||
            new_password.length < 8
        ) {
            if (new_password.length < 8) {
                Swal.fire("Warning", "Password must be at least 8 characters...!", "warning");
            } else if (new_password != confirmed_new_password) {
                Swal.fire("Warning", "New password and confirm password should match", "warning");

            }
        } else {
            const reset_data = {
                new_password,
                old_password,
                user_id
            };
            AuthAPI.changePasswordApi(reset_data).then((res) => {
                if (res.data.status_code == 0) {
                    Swal.fire("Success", "" + res.data.message, "success");
                    setOldPassword("");
                    setNewPassword("");
                    setConfirmedNewPassword("");
                } else {
                    Swal.fire("Warning", "" + res.data.message, "warning");
                }
            }).catch((error) => {
                Swal.fire("Warning", ""+error.response.data.message, "warning");
            });
        }
    };

    return (<>
        <div className="row">
            <div className="col-12">
                <h3> Change Password </h3>
            </div>
        </div>
        <section>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                <div className="align-items-center">
                                    <form
                                        id="pwdchange-form"
                                        onSubmit={(e) => {
                                            handleSubmit(e);
                                        }}
                                    >
                                        <div className="form-group">
                                            <TextInput
                                                label={'Old Password'}
                                                placeholder={'Enter old password'}
                                                type={'password'}
                                                value={old_password}
                                                onchange={(e) => setOldPassword(e.target.value)}
                                                required={true}
                                            />

                                        </div>
                                        <div className="form-group">
                                            <TextInput
                                                label={'New Password'}
                                                placeholder={'Enter new password'}
                                                type={'password'}
                                                value={new_password}
                                                onchange={(e) => setNewPassword(e.target.value)}
                                                required={true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextInput
                                                label={'Retype New Password'}
                                                placeholder={'Re-Enter new password'}
                                                type={'password'}
                                                value={confirmed_new_password}
                                                onchange={(e) => setConfirmedNewPassword(e.target.value)}
                                                required={true}
                                            />
                                        </div>
                                        <div className="d-flex flex-sm-row flex-column justify-content-end">
                                            <button
                                                type="submit"
                                                className="btn btn-primary mr-sm-2 mb-1"
                                            >
                                                Save Changes
                                            </button>
                                            <button type="reset" className="btn btn-secondary mb-1">
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                </div>
                                <div className="col-md-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}
export default ChangePassword;
