import API from "./AxiosHeaders";
import {endPoints} from "./APIEndPoints";

const saveNewLocation = async (inputData) => {
    return await API.commonInstance.post(endPoints.saveLocation,inputData);
}

const editLocation = async (data) => {
    return await API.commonInstance.post(endPoints.editLocation, data);
}

const getLocationList = async () => {
    return await API.commonInstance.get(endPoints.getAllLocation);
}

const deleteLocation = async (locationId) => {
    return await API.commonInstance.delete(endPoints.deleteLocation+`/`+locationId);
}


const LocationAPI = {
    getLocationList,
    saveNewLocation,
    editLocation,
    deleteLocation
};

export default LocationAPI;