import API from "./AxiosHeaders";
import {endPoints} from "./APIEndPoints";

const getAllUsers = async () => {
    return await API.commonInstance.get(endPoints.getAllUsers);
}

const saveNewUser = async (data) => {
    return await API.commonInstance.post(endPoints.saveUser, data);
}

const editUserDetails = async (userId,data) => {
    return await API.commonInstance.put(endPoints.editUser+`/`+userId, data);
}

const deleteUser = async (userId) => {
    return await API.commonInstance.delete(endPoints.deleteUser+`/`+userId);
}

const requestAccountDelete = async (data) => {
    return await API.authInstance.post(endPoints.accountDeleteRequest, data);
}


const UserAPI = {
    getAllUsers,
    saveNewUser,
    editUserDetails,
    deleteUser,
    requestAccountDelete
};

export default UserAPI;