import React from 'react';
import Location from "./pages/master-files/Location";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Branch from "./pages/master-files/Branch";
import Category from "./pages/master-files/Category";
import User from "./pages/master-files/User";
import Login from "./pages/Login";
import PrivateRoutes from "./utils/PrivateRoutes";
import AppLayout from "./layouts/AppLayout";
import ForgotPassword from "./pages/ForgotPassword";
import Product from "./pages/Product";
import NewProduct from "./pages/main-master-files/NewProduct";
import EditProduct from "./pages/EditProduct";
import StockAdjustment from "./pages/StockAdjustment";
import Customer from "./pages/Customer";
import Order from "./pages/Order";
import StockManagement from "./pages/StockManagement";
import StockAdjustmentHistory from "./pages/StockAdjustmentHistory";
import ViewCustomer from "./pages/ViewCustomer";
import ViewOrder from "./pages/ViewOrder";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import MasterCategoryOrg from './pages/master-files/MasterCategoryOrg';
import MasterProductOrg from './pages/master-files/MasterProductOrg';
import MasterCategory from './pages/main-master-files/MasterCategory';
import MasterProduct from './pages/main-master-files/MasterProduct';
import PageNotFound from './pages/PageNotFound';
import RequestAccountDelete from './pages/RequestAccountDelete';

function App() {
  return (<>
          <BrowserRouter>
              <Routes>
                  {/*<Route index element={<Login />} />*/}
                  <Route path="/" element={<Login/>} />
                  <Route path="/delete-user" element={<RequestAccountDelete />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path='password/reset/:token/:email' element={<ResetPassword />} />
                  <Route path="*" element={<PageNotFound/>} />
                  <Route element={<PrivateRoutes/>}>
                      <Route element={<AppLayout />}>
                          <Route path="/locations" element={<Location />} />
                          <Route path="/branches" element={<Branch />} />
                          <Route path="/categories" element={<Category />} />
                          <Route path="/users" element={<User />} />
                          <Route path="/products" element={<Product />} />
                          <Route path="/product-add" element={<NewProduct />} />
                          <Route path="/product-edit/:id" element={<EditProduct />} />
                          <Route path="/stock-management" element={<StockManagement/>} />
                          <Route path="/stock-adjustment" element={<StockAdjustment/>} />
                          <Route path="/adjustment-history" element={<StockAdjustmentHistory/>} />
                          <Route path="/customers" element={<Customer/>} />
                          <Route path="/customer/:id" element={<ViewCustomer/>} />
                          <Route path="/orders" element={<Order/>} />
                          <Route path="/order/:id" element={<ViewOrder/>} />
                          <Route path="/change-password" element={<ChangePassword/>} />
                          <Route path="/get-categories" element={<MasterCategoryOrg/>} />
                          <Route path="/get-products" element={<MasterProductOrg/>} />
                          <Route path="/master-categories" element={<MasterCategory />} />
                          <Route path="/master-products" element={<MasterProduct />} />
                      </Route>
                  </Route>
              </Routes>
          </BrowserRouter>
  </> );
}

export default App;
