const EditableSelection = ({ editable, value, onChange, list }) => {
  const handleChange = newValue => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return editable ? (
    <select className="form-control" id="category" name="category" value={value} onChange={handleChange}>
    <option value="" disabled selected>Select category </option>
    {list.map((item, index) => (<>
        <option value={item.key} style={{fontWeight:500}} key={item.key}> {item.name}</option>

        {item.children.map((subItem, i) => (
            <option value={subItem.key} key={subItem.key}>&emsp; {subItem.name}</option>
        ))}

    </>))}
</select>
  ) : (
    value
  );
};

export default EditableSelection;
