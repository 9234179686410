import React, { useState, useEffect } from "react";

import { Table, Input,Button } from "antd";
import {Link} from "react-router-dom";
import DropDown from "../components/form/DropDown";
import BranchAPI from "../services/BranchAPIServices";
import StockAPI from "../services/StockAPIServices";
import { CSVLink } from "react-csv";
import moment from "moment";

function StockManagement() {
    const [branch_name, setBranchName] = useState("");
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [branchList, setBranchList] = useState([]);
    const [originalProductList, setOriginalProductList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [exportFileName, setExportFileName] = useState("");
    const [branchNameText, setBranchNameText] = useState("");

    useEffect(() => {
       
        BranchAPI.getActiveBranches().then((response)=>{
            setBranchList(response.data.data);
        });

    }, []);

    const columns = [
        {
            title: 'Product Name',
            dataIndex: ['product', 'title'],
            key: 'title',
        },
        {
            title: 'Category',
            dataIndex: ['product', 'category','name'],
            key: 'category_id',
        },
        {
            title: 'Current available stock',
            dataIndex: 'quantity',
            key: 'quantity',
            sorter: (a, b) => a.quantity - b.quantity,
        },
    ];

    const changeBranch = (e) => {
        setBranchName(e.target.value);
        setBranchNameText(e.target.selectedOptions[0].text)
        setProductList([]);
        setOriginalProductList([]);

        setLoading(true);
        StockAPI.getBranchStockItems(e.target.value).then((response)=>{
            setProductList(response.data.data);
            setOriginalProductList(response.data.data)
            setLoading(false);
        });
    };

    const search = value => {
        setLoading(true);
        let filterTable = [];
        setSearchText(value);
        if(value == ""){
            filterTable = originalProductList;
        }else{
            filterTable = originalProductList.filter(item =>
                item.product.category.name.toLowerCase().includes(value.toLowerCase()) ||
                item.product.title.toLowerCase().includes(value.toLowerCase())
            );
        }
        setProductList(filterTable);
        setLoading(false);
    };

    const handleExport = () => {

        setExportFileName(searchText);
        let dataArrayset = [["Branch",": "+branchNameText,"","","Stock Date : "+moment().format("DD/MM/YYYY")],["","","","",""],["Product id", "Title","","Category" ,"Available stock"]];
        for (var i = 0; i < productList.length; i++) {
            var newRow = [productList[i].product.id, productList[i].product.title,"",productList[i].product.category.name, productList[i].quantity]
            dataArrayset.push(newRow);
        }

        setCsvData(dataArrayset);
    };

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <h3> Stock Management </h3>
                </div>
                <div className="col-6">
                    <Link to="/adjustment-history">
                        <button
                            type="button"
                            className="btn btn-primary mb-3 d-block float-right ml-1">
                            Adjustment History
                        </button>
                    </Link>
                    <Link to="/stock-adjustment">
                        <button
                            type="button"
                            className="btn btn-primary mb-3 d-block float-right">
                            <i class="ft-plus"></i> Adjustment
                        </button>
                    </Link>
                </div>
            </div>
            <section>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="row g-3 align-items-center">
                                        <div className="col-md-4">
                                            <DropDown
                                                label={"Select Branch"}
                                                placeholder="select branch"
                                                name={'branch_name'}
                                                value={branch_name}
                                                onchange={changeBranch}
                                                list={branchList}
                                            />
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-4">
                                            <Input.Search
                                                placeholder="Search by..."
                                                enterButton
                                                onSearch={search}
                                                className="mt-3"
                                                allowClear
                                            />
                                        </div>
                                        <div className="col-md-2">
                                        <Button style={{marginTop:'20px'}}>
                                                <CSVLink
                                                    data={csvData}
                                                    filename={`${exportFileName}.csv`}
                                                    onClick={(event, done) => {
                                                        handleExport();
                                                    }}
                                                >
                                                    <i class="ft-download"></i> Stock CSV
                                                </CSVLink>
                                            </Button>
                                        </div>

                                        <div className="col-md-12 mt-2">
                                            <Table columns={columns} dataSource={productList} loading={loading} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default StockManagement;
