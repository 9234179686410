import React, { useState, useEffect } from "react";

import { Table, Modal } from "antd";
import {Link} from "react-router-dom";
import StockAPI from "../services/StockAPIServices";
import moment from "moment";


function StockAdjustmentHistory() {
    const [tableData, setTableData] = useState([]);
    const [itemsData, setItemsData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        StockAPI.getAdjustmentList().then((response)=>{
            setTableData(response.data.data);
            setLoading(false);
        });

    }, []);
    const ItemColumns = [
        {
            title: 'Product Name',
            key: 'name',
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                            <label>{record.product.title}</label>
                        </div>
                    </>
                );
            },
        },
        {
            title: 'Adjusted Type ',
            dataIndex: 'adjust_type',
            key: 'adjust_type',
        },
        {
            title: 'Count',
            dataIndex: 'adjust_count',
            key: 'adjust_count',
        },
    ];
    const columns = [
        {
            title: 'Branch Name',
            key: 'name',
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                          <label>{record.header.branch.name}</label>
                        </div>
                    </>
                );
            },
        },
        {
            title: 'Adjusted Date',
            dataIndex: 'date',
            key: 'date',
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                            <label>{moment(record).format('DD/MM/YYYY')}</label>
                        </div>
                    </>
                );
            },
        },
        {
            key: "action",
            title: "",
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                                <button className="btn info" onClick={() => Edit(record.details)}>
                                    <i class="ft-eye"></i>
                                </button>
                        </div>
                    </>
                );
            },
        },
    ];

    const Edit = (data) => {
        setVisible(true);
        setItemsData(data);
    };
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <h3> Stock Adjustment History </h3>
                </div>
                <div className="col-6">
                    <Link to="/stock-adjustment">
                        <button
                            type="button"
                            className="btn btn-primary mb-3 d-block float-right">
                            <i class="ft-plus"></i> Adjustment
                        </button>
                    </Link>
                </div>
            </div>
            <section>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="row g-3 align-items-center">
                                        <div className="col-md-12">
                                            <Table columns={columns} dataSource={tableData} loading={loading} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                title={"Items"}
                visible={visible}
                onCancel={() => setVisible(false) }
                onOk={() => setVisible(false) }
            >
                <Table columns={ItemColumns} dataSource={itemsData} />
            </Modal>
        </>
    );
}
export default StockAdjustmentHistory;
