import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { Outlet } from "react-router-dom";
import RoutesAPI from "../services/RoutesAPIServices";
import React, { useState, useEffect } from "react";

function AppLayout() {
    let root_pathname = window.location.pathname;
    root_pathname = root_pathname.slice(1);
    let root_array = root_pathname.split("/");
    let current_path = root_array[0];
    const [access, setAccess] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        if(current_path == 'change-password'){
            setAccess(true);
            setPageLoading(false);
        }else{
            const path_data = {
                route: current_path,
            };
            RoutesAPI.checkAccessRoute(path_data).then((response) => {
                setAccess(response.data.data);
                setPageLoading(false);
            }).catch((error) => { console.error(error) });
        }
      

    }, []);

    return (<>
        {access ? (<>
            <Navbar />
            <div id="layout-wrapper">
                <div className="wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="main-content">
                            <div className="content-overlay"></div>
                            <div className="content-wrapper">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>) : (!pageLoading && !access ? (<>
            <section id="error" class="auth-height">
                <div class="container-fluid">
                    <div class="row full-height-vh">
                        <div class="col-12 d-flex align-items-center justify-content-center">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <img
                                        src="../../../app-assets/img/gallery/error.png"
                                        alt=""
                                        class="img-fluid error-img mt-2"
                                        height="300"
                                        width="400"
                                    />
                                    <h1 class="mt-4">401 - Access Denied!</h1>
                                    <div class="w-75 error-text mx-auto mt-4">
                                        <p>Sorry You don't have permission to this page.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>) : (<>

            <div class="container-fluid">
                <div class="row full-height-vh">
                    <div class="col-12 d-flex align-items-center justify-content-center">
                        <h4> Loading...</h4>
                    </div>
                </div>
            </div></>))}

    </>);
}

export default AppLayout;